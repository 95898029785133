import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "../../assets/scss/theme-base/table.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPackageDashBoard } from "../../store/dashboard/slice";
import { formatDateUser } from "../../components/utils";

const ChartPackage = ({
  startDate,
  endDate,
}: {
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const today = new Date();
    dispatch(
      getPackageDashBoard({
        start_date: startDate
          ? formatDateUser(startDate)
          : formatDateUser(today),
        end_date: endDate ? formatDateUser(endDate) : formatDateUser(today),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDate, startDate]);

  const { packageDashboardData } = useSelector(
    (state: any) => ({
      packageDashboardData: state.Dashboard.packageDashboardData,
    }),
    shallowEqual
  );

  const options: ApexOptions = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    series: [
      packageDashboardData?.data?.total_in_transit || 0,
      packageDashboardData?.data?.total_completed_on_time || 0,
      packageDashboardData?.data?.total_completed_late || 0,
    ],
    labels: ["Đang vận chuyển", "Hoàn thành đúng giờ", "Hoàn thành trễ hạn"],
    colors: ["#fea500", "#0000ff", "#ff0000"],
    dataLabels: { enabled: false },
    plotOptions: {
      pie: {
        donut: {
          size: "75%",
        },
        startAngle: 0,
        endAngle: 360,
      },
    },
  };

  return (
    <div className="px-4">
      <div className="flex justify-content-between align-items-center mb-8">
        <p className="font-semibold text-600">Kiện hàng</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <p className="text-base font-semibold text-600">Tổng số kiện</p>
      </div>
      <div>
        <p
          style={{
            marginTop: "1rem",
            fontSize: "32px",
            fontWeight: "700",
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          {packageDashboardData?.data?.total_transit}
        </p>
        <ReactApexChart
          options={options}
          series={options.series}
          type="donut"
          height={"200px"}
        />
        <div className="mt-4 flex justify-content-center">
          <div
            className="flex gap-1 mx-7 w-12 md:w-6"
            style={{ alignItems: "center" }}
          >
            <div
              className="dot"
              style={{
                backgroundColor: "#fea500",
              }}
            ></div>
            <p className="col-8">Đang vận chuyển</p>
            <p className="col-2 flex justify-content-end">
              {packageDashboardData?.data?.total_in_transit}
            </p>
          </div>
        </div>
        <div className=" flex justify-content-center">
          <div
            className="flex gap-1 mx-7 w-12 md:w-6"
            style={{ alignItems: "center" }}
          >
            <div
              className="dot"
              style={{
                backgroundColor: "#0000ff",
              }}
            ></div>
            <p className="col-8">Hoàn thành đúng giờ</p>
            <p className="col-2 flex justify-content-end">
              {packageDashboardData?.data?.total_completed_on_time}
            </p>
          </div>
        </div>
        <div className="flex justify-content-center">
          <div
            className="flex gap-1 mx-7 w-12 md:w-6"
            style={{ alignItems: "center" }}
          >
            <div
              className="dot"
              style={{
                backgroundColor: "#ff0000",
              }}
            ></div>
            <p className="col-8">Hoàn thành trễ hạn</p>
            <p className="col-2 flex justify-content-end">
              {packageDashboardData?.data?.total_completed_late}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartPackage;
