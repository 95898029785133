import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import "../../assets/scss/theme-base/table.scss";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import ModalAddPlane from "./ModalAddPlane";
import ModalEditPlane from "./ModalEditPlane";
import ActionMenu from "../../components/ActionMenu";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import ModelDelete from "../../components/ModalDelete";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getPlaneData,
  deletePlane,
  setPlaneDataNull,
} from "../../store/plane/slice";
import { Toast } from "primereact/toast";
import LoadingSpinner from "../../components/LoadingSpinner";
import { InputIcon } from "primereact/inputicon";
import { optionsAddressSpx } from "../../components/Interface";
import { optionsPlane } from "../../components/Interface";
import { IconPlaneCustom } from "../../components/Icon";

export function changeDateFormat(dateString: string): string {
  if (typeof dateString !== "string") {
    return "";
  }
  const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
  const match = dateString.match(regex);

  if (!match) {
    return "";
  }

  const [, year, month, day, hours, minutes, seconds] = match;
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

function Plane() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);
  const [inputSearch, setInputSearch] = useState<string>("");

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const {
    planeData,
    insertPlaneData,
    deletePlaneData,
    deletePlaneError,
    updatePlaneData,
    dataUserInfo,
  } = useSelector(
    (state: any) => ({
      planeData: state.Plane.planeData,
      insertPlaneData: state.Plane.insertPlaneData,
      deletePlaneData: state.Plane.deletePlaneData,
      deletePlaneError: state.Plane.deletePlaneError,
      updatePlaneData: state.Plane.updatePlaneData,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  const fetchData = (page: number, limit: number) => {
    dispatch(getPlaneData({ page, limit }));
  };

  useEffect(() => {
    if (insertPlaneData || deletePlaneData || updatePlaneData) {
      fetchData((first + rows) / rows, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, insertPlaneData, deletePlaneData, updatePlaneData]);

  useEffect(() => {
    if (inputSearch) {
      const timeout = setTimeout(() => {
        dispatch(
          getPlaneData({
            page: (first + rows) / rows,
            limit: rows,
            query: {
              s_global: inputSearch,
            },
          })
        );
      }, 400);
      return () => clearTimeout(timeout);
    } else {
      fetchData((first + rows) / rows, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, first, rows, inputSearch]);

  useEffect(() => {
    setDataTable(planeData?.data);
  }, [planeData]);

  //sửa dữ liệu
  const handleEdit = (rowData: any) => {
    if (selectArrayPermisstion?.includes("UPDATE_PLANE")) {
      setModalEdit(true);
      setRowData(rowData);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Bạn không có quyền",
        life: 3000,
      });
    }
  };

  //xóa dữ liệu
  const handleDelete = (rowData: any) => {
    if (selectArrayPermisstion?.includes("DELETE_PLANE")) {
      setModalDelete(true);
      setRowData(rowData);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Bạn không có quyền",
        life: 3000,
      });
    }
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(deletePlane(id));
      }
      setModalDelete(false);
    }
  };

  //Menu hành động
  const actionBodyTeamplate = (rowData: any) => {
    return (
      <ActionMenu
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
        type=""
      />
    );
  };

  useEffect(() => {
    if (deletePlaneData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Xóa chuyến bay thành công",
        life: 3000,
      });
      dispatch(setPlaneDataNull());
    }
    if (deletePlaneError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: deletePlaneError.response?.data?.message,
        life: 3000,
      });
      dispatch(setPlaneDataNull());
    }
  }, [dispatch, deletePlaneData, deletePlaneError]);

  // Reset Filters
  // const resetFilters = () => {
  //   setInputSearch("");
  // };
  // Reset Filters
  // const handleClearFilters = () => {
  //   resetFilters();
  //   fetchData((first + rows) / rows, rows);
  // };

  const addressStartTemplate = (rowData: any) => {
    const option = optionsAddressSpx.find(
      (e) => e.code === rowData?.departure_point
    );
    return (
      <>
        <p> {option ? option.label : ""}</p>
      </>
    );
  };

  const addressEndTemplate = (rowData: any) => {
    const option = optionsAddressSpx.find(
      (e) => e.code === rowData?.destination
    );
    return (
      <>
        <p> {option ? option.label : ""}</p>
      </>
    );
  };

  const typePlaneTemplate = (rowData: any) => {
    const option = optionsPlane.find((e) => e.code === rowData?.type);
    return (
      <>
        <p> {option ? option.label : ""}</p>
      </>
    );
  };

  const statusPlaneNameTemplate = (rowData: any) => {
    return (
      <div className="flex flex-row align-items-center py-2 gap-2">
        {IconPlaneCustom}
        <div>{rowData?.code}</div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex justify-content-between mb-3">
        <div className="flex gap-3 w-8">
          <Button
            label="Thêm mới"
            onClick={() => {
              setAddDialogVisible(true);
            }}
            style={{ height: "35px" }}
            disabled={!selectArrayPermisstion?.includes("CREATE_USER")}
          />
          <IconField
            iconPosition="left"
            className="search-item flex items-center w-8"
          >
            <InputIcon className="pi pi-search"></InputIcon>
            <InputText
              placeholder="Tìm kiếm"
              className="border-1"
              style={{
                height: "35px",
              }}
              value={inputSearch}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputSearch(e.target.value)
              }
            />
          </IconField>
        </div>
      </div>
      <div className="bg-white border-round-md shadow-1 p-3">
        {planeData && planeData.data ? (
          <>
            <DataTable
              value={dataTable}
              emptyMessage="Không có dữ liệu"
              size="small"
              className="custom-table"
            >
              <Column
                header="STT"
                body={(rowData, options) => (
                  <div className="text-center">
                    {first + options.rowIndex + 1}
                  </div>
                )}
                style={{ width: "5%" }}
                alignHeader={"center"}
                align={"center"}
              />
              <Column
                header="SHCB"
                field="code"
                style={{ minWidth: "50px" }}
                body={statusPlaneNameTemplate}
              />
              <Column
                header="Loại tàu bay"
                field="type"
                style={{ minWidth: "50px" }}
                body={typePlaneTemplate}
              />
              <Column
                header="Điểm khởi hành"
                field="departure_point"
                style={{ minWidth: "100px" }}
                body={addressStartTemplate}
              />
              <Column
                header="Điểm đến"
                field="destination"
                style={{ minWidth: "100px" }}
                body={addressEndTemplate}
              />
              <Column
                header="Mô tả"
                field="description"
                style={{ minWidth: "100px" }}
              />
              <Column body={actionBodyTeamplate} />
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={planeData?.total}
              rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <ModalEditPlane
        visible={modalEdit}
        onHide={() => setModalEdit(false)}
        rowData={rowData}
        toast={toast}
      />
      <ModalAddPlane
        toast={toast}
        visible={addDialogVisible}
        onHide={() => setAddDialogVisible(false)}
      />
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: "center" }}>
            Bạn chắc chắn muốn xóa chuyến bay này?
          </div>
        }
      />
    </div>
  );
}

export default Plane;
