import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  exportExcel,
  getContainerInfoData,
  setContainerDataNull,
} from "../../store/container/slice";
import { Dropdown } from "primereact/dropdown";
import { changeDateFormat } from "../../components/utils";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

export default function ModalDetailContainer({
  visible,
  onHide,
  rowData,
}: {
  visible: boolean;
  onHide: () => void;
  rowData: any;
}) {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [packageMove, setPackageMove] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const toast = useRef<Toast>(null);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const {
    containerInfoData,
    exportExcelContainerData,
    exportExcelContainerError,
  } = useSelector(
    (state: any) => ({
      containerInfoData: state.Container.containerInfoData,
      loadingInfo: state.Container.loadingInfo,
      exportExcelContainerData: state.Container.exportExcelContainerData,
      exportExcelContainerError: state.Container.exportExcelContainerError,
    }),
    shallowEqual
  );

  const fetchData = (
    containerId: number,
    isShipped: boolean,
    page: number,
    limit: number
  ) => {
    dispatch(
      getContainerInfoData({ containerId, isShipped, page, limit, query: {} })
    );
  };

  useEffect(() => {
    if (rowData) {
      fetchData(rowData?.id, packageMove, (first + rows) / rows, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, first, rows, rowData, packageMove]);

  const listStatus = [
    { label: "Kiện hàng đã chuyển" },
    { label: "Kiện hàng chưa chuyển" },
  ];

  useEffect(() => {
    if (tabMenuActive === 0) {
      setPackageMove(true);
    } else {
      setPackageMove(false);
    }
  }, [tabMenuActive]);

  const rowClassName = (rowData: any, rowIndex: any) => {
    return "custom-row";
  };

  useEffect(() => {
    setDataTable(containerInfoData?.data);
  }, [dispatch, containerInfoData]);

  const rowsPerPageOptions = [
    { name: "10", code: 10 },
    { name: "20", code: 20 },
    { name: "30", code: 30 },
    { name: "100", code: 100 },
    { name: "200", code: 200 },
    { name: "500", code: 500 },
    { name: "Tất cả", code: Number(containerInfoData?.total) },
  ];

  const handlePrintExport = () => {
    dispatch(
      exportExcel({
        containerId: rowData?.id,
        isShipped: packageMove,
        page: (first + rows) / rows,
        limit: rows,
        query: {},
      })
    );
  };

  useEffect(() => {
    if (exportExcelContainerData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: `Xuất Excel thành công`,
        life: 3000,
      });
      const link = document.createElement("a");
      link.href = exportExcelContainerData.data;
      link.download = "File.xlsx";
      link.click();
      dispatch(setContainerDataNull());
    }
    if (exportExcelContainerError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${exportExcelContainerError.message}`,
        life: 3000,
      });
      dispatch(setContainerDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportExcelContainerData, exportExcelContainerError]);

  return (
    <div>
      <Dialog
        visible={visible}
        onHide={() => {
          onHide();
          setDataTable([]);
          setTabmenuActive(0);
          setRows(10);
        }}
        header="Chi tiết"
        className="w-10 md:w-6"
        style={{ minHeight: "50vh" }}
      >
        <div className="flex gap-3 justify-content-between my-2">
          <div>
            <h4 className="" style={{ color: "#295bac" }}>
              {rowData?.name}
            </h4>
          </div>
          <div>
            <Button
              label="Xuất Excel"
              className="mr-3"
              icon="pi pi-print"
              severity="danger"
              style={{ height: "35px" }}
              onClick={() => handlePrintExport()}
              // disabled={!selectArrayPermisstion?.includes("IMPORT_EXCEL")}
            />
          </div>
        </div>
        <div className="bg-white border-round-md shadow-1 p-2">
          <div className="flex justify-content-between align-items-center ">
            <TabMenu
              model={listStatus.map((item, index) => ({
                ...item,
                template: () =>
                  tabMenuTemplate(item, index, tabMenuActive, setTabmenuActive),
              }))}
              activeIndex={tabMenuActive}
              onTabChange={(e) => setTabmenuActive(e.index)}
            />
          </div>
          {
            // selectArrayPermisstion?.includes("VIEW_PACKAGE") ? (
            containerInfoData && containerInfoData.data && (
              <div>
                <DataTable
                  value={dataTable}
                  emptyMessage="Không có dữ liệu"
                  size="small"
                  className="package-table"
                  reorderableColumns
                  // rowClassName={rowClassName}
                  scrollable
                  style={{ minHeight: "500px" }}
                >
                  <Column
                    header="STT"
                    body={(rowData, options) => (
                      <div className="text-center">
                        {first + options.rowIndex + 1}
                      </div>
                    )}
                    style={{ width: "5%" }}
                    alignHeader={"center"}
                    align={"center"}
                    frozen
                  />
                  <Column field="carton_no" header="Mã kiện hàng"></Column>
                  <Column field="shopee_po_id" header="Shoppe PO ID"></Column>
                  <Column field="warehouse_name" header="Kho hàng"></Column>
                  <Column field="amount_product" header="Số lượng"></Column>
                  <Column
                    header="Ngày nhận"
                    body={(rowData) => (
                      <div>{changeDateFormat(rowData?.delivery_date)}</div>
                    )}
                  ></Column>
                </DataTable>
                <div className="card flex justify-content-center align-items-center">
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={containerInfoData?.total}
                    onPageChange={onPageChange}
                  />
                  <Dropdown
                    value={rows}
                    onChange={(e) => setRows(e.value)}
                    options={rowsPerPageOptions}
                    optionLabel="name"
                    optionValue="code"
                    style={{ height: "40px" }}
                  />
                </div>
              </div>
            )
          }
        </div>
      </Dialog>
    </div>
  );
}
