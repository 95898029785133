import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import "../../assets/scss/theme-base/table.scss";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { TabMenu } from "primereact/tabmenu";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import LoadingSpinner from "../../components/LoadingSpinner";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import {
  getPackageReportData,
  setReportDataNull,
} from "../../store/report/slice";
import { formatDateUser } from "../../components/utils";
import { exportExcelPackage } from "../../store/report/slice";

interface Status {
  name: string;
  code: string;
}

export default function Report() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [rowData, setRowData] = useState<any>(null);
  const [dataTable, setDataTable] = useState([]);
  const today = new Date();
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [dates, setDates] = useState<[Date, Date] | null>([today, today]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);
  const [statusPackage, setStatusPackage] = useState<string>("");

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const query: { [key: string]: string } = {};

      if (inputSearch) query.s_global = inputSearch;
      query.start_date =
        dates && dates[0] ? formatDateUser(dates[0]) : formatDateUser(today);
      query.end_date =
        dates && dates[1] ? formatDateUser(dates[1]) : formatDateUser(today);
      query.s_status = statusPackage;
      if (inputSearch) query.s_global = inputSearch;
      if (Object.keys(query).length > 0) {
        dispatch(
          getPackageReportData({
            page: (first + rows) / rows,
            limit: rows,
            query: query,
          })
        );
      } else {
        dispatch(
          getPackageReportData({ page: (first + rows) / rows, limit: rows })
        );
      }
    }, 400);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rows, first, inputSearch, dates, statusPackage, inputSearch]);

  const { packageReportData, dataUserInfo, exportExcelData, exportExcelError } =
    useSelector(
      (state: any) => ({
        packageReportData: state.Report.packageReportData,
        exportExcelData: state.Report.exportExcelData,
        exportExcelError: state.Report.exportExcelError,
        dataUserInfo: state.Auth.dataUserInfo,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (exportExcelData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: `Xuất Excel thành công`,
        life: 3000,
      });
      const link = document.createElement("a");
      link.href = exportExcelData.fileResult;
      link.download = "File.xlsx";
      link.click();
      dispatch(setReportDataNull());
    }
    if (exportExcelError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${exportExcelError.message}`,
        life: 3000,
      });
      dispatch(setReportDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportExcelData, exportExcelError]);

  useEffect(() => {
    if (packageReportData) {
      setDataTable(packageReportData?.data);
      setLoading(false);
    }
  }, [packageReportData]);

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  const listStatus = [
    { label: "Tất cả" },
    { label: "Mới" }, //chờ vận chuyển
    { label: "Đang vận chuyển" },
    { label: "Đã vận chuyển" },
  ];

  useEffect(() => {
    if (tabMenuActive === 0) {
      setStatusPackage("");
    } else if (tabMenuActive === 1) {
      setStatusPackage("WAITING");
    } else if (tabMenuActive === 2) {
      setStatusPackage("IN_TRANSIT");
    } else if (tabMenuActive === 3) {
      setStatusPackage("DELIVERED");
    }
  }, [tabMenuActive]);

  // const listStatus = {
  //   WAITING: "WAITING", // cho van chuyen
  //   IN_TRANSIT: "IN_TRANSIT", // trong quá trình vận chuyển
  //   LATE_DELIVERY: "LATE_DELIVERY", // giao hàng trễ
  //   DELIVERED: "DELIVERED", // đã giao hàng
  // };

  // const listStatus: Status[] = [
  //   { name: "Tất cả", code: "" },
  //   { name: "Mới", code: "WAITING" }, //chờ vận chuyển
  //   { name: "Đang vận chuyển", code: "IN_TRANSIT" },
  //   { name: "Đã vận chuyển", code: "DELIVERED" },
  // ];

  // const rowClassName = (rowData: any, rowIndex: any) => {
  //   return "custom-row";
  // };

  const rowsPerPageOptions = [
    { name: "10", code: 10 },
    { name: "20", code: 20 },
    { name: "30", code: 30 },
    { name: "100", code: 100 },
    { name: "200", code: 200 },
    { name: "500", code: 500 },
    { name: "Tất cả", code: Number(packageReportData?.total) },
  ];

  const columns: any[] = [
    {
      label: "Service code",
      value: "service_code",
      minWidth: "100px",
    },
    {
      label: "MAWB",
      value: "mawb",
      minWidth: "200px",
    },
    {
      label: "Carton",
      value: "carton_no",
      minWidth: "150px",
    },
    {
      label: "Dest",
      value: "warehouse",
      minWidth: "100px",
    },

    {
      label:
        "(HCM/DN/VL)越南汇翔到清关货场接货车牌 Biển số xe từ kho HN đến kho thông quan nhận hàng",
      value: "license_plate_phase_1",
      minWidth: "250px",
    },
    {
      label:
        "(汇翔VN)在清关场站开始接货时间 Thời gian bắt đầu nhận hàng ở kho thông quan",
      value: "phase_1_actual_time",
      minWidth: "250px",
    },
    {
      label:
        "在清关场站接完货发出时间 Thời gian sau khi nhận hàng xong ở kho thông quan, bắt đầu xuất phát",
      value: "phase_3_actual_time",
      minWidth: "250px",
    },
    {
      label: "到HN 机场时间 Thời gian đến sân bay Nội Bài ",
      value: "phase_4_actual_time",
      minWidth: "150px", // phase4
    },
    {
      label:
        "在清关场站-HN机场考核时效/h Thời gian dự kiến từ kho thông quan đến sân bay",
      value: "time_to_transit_config",
      minWidth: "250px", //time_to_transit_config
    },
    {
      label:
        "在清关场站-HN机场实际时效/h Thời gian thực tế từ kho thông quan đến sân bay",
      value: "time_to_transit_actual",
      minWidth: "250px", //time_to_transit_actual
    },
    {
      label: "超时原因 Nguyên nhân kéo dài thời gian",
      value: "time_to_transit_resion",
      minWidth: "250px", //time_to_transit_resion
    },
    {
      label: "HN--HCM 航班预计起飞时间",
      value: "plane_estimated_start_time",
      minWidth: "150px",
    }, //plane_estimated_start_time
    { label: "航班号 Số hiệu máy bay", value: "plane_code", minWidth: "120px" }, //plane_code
    {
      label: "小飞机/大飞机Tàu bay nhỏ/Tàu bay to",
      value: "plane_type",
      minWidth: "150px", //plane_type
    },
    {
      label: "河内(HN)实际起飞时间",
      value: "plane_actual_start_time",
      minWidth: "150px",
    }, //plane_actual_start_time
    {
      label: "胡志明(HCM/ĐN/CẦN THƠ) 到达时间 Thời gian đến HCM/ĐN/CẦN THƠ",
      value: "plane_actual_end_time",
      minWidth: "250px", //plane_actual_end_time
    },
    {
      label: "(HCM/DN/VL) SPX仓派送车牌 Biển số xe giao đến SPX HCM",
      value: "license_plate_phase_5",
      minWidth: "250px", //license_plate_phase_5
    },
    {
      label: "派送SPX车辆出发时间 Thời gian xuất phát đến SPX",
      value: "phase_7_actual_time",
      minWidth: "250px", //phase_7_actual_time
    },
    {
      label: "SPX车辆到仓时间 Thời gian đến kho SPX",
      value: "phase_8_actual_time",
      minWidth: "250px", //phase_8_actual_time
    },
    {
      label: "胡志明发车-车到客户仓库考核时效/h Thời gian dự kiến tới kho SPX",
      value: "time_to_spx_config",
      minWidth: "250px", //time_to_spx_config
    },
    {
      label: "胡志明发车-车到客户仓库实际时效/h Thời gian thực tế đến kho SPX",
      value: "time_to_spx_actual",
      minWidth: "250px", //time_to_spx_actual
    },
    {
      label: "超时原因 Nguyên nhân kéo dài thời gian",
      value: "time_to_spx_resion",
      minWidth: "250px", //time_to_spx_resion
    },
    {
      label: "SPX 开始收货时间 Thời gian SPX bắt đầu nhận hàng",
      value: "phase_9_actual_time",
      minWidth: "250px", //phase_9_actual_time
    },
    {
      label: "SPX签收完成时间 Thời gian SPX kí nhận",
      value: "phase_11_actual_time",
      minWidth: "250px", //phase_11_actual_time
    },
    {
      label:
        "SPX车辆到仓时间-SPX开始收货时间考核时效/h Thời gian từ lúc tới kho đến lúc SPX bắt đầu nhận hàng",
      value: "time_to_sign_config",
      minWidth: "250px", //time_to_sign_config
    },
    {
      label: "等待SPX开始收货时间",
      value: "time_to_sign_actual",
      minWidth: "120px", //time_to_sign_actual
    },
    {
      label: "超时原因 Nguyên nhân kéo dài thời gian",
      value: "time_to_sign_resion",
      minWidth: "120px", //time_to_sign_resion
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState<any[]>(columns);

  useEffect(() => {
    const storedColumns = localStorage.getItem("visibleColumns");

    if (storedColumns) {
      const selectedValues = JSON.parse(storedColumns);
      const newVisibleColumns = columns.filter((column) =>
        selectedValues.includes(column.value)
      );
      setVisibleColumns(newVisibleColumns);
    } else {
      setVisibleColumns(columns);
    }
  }, []);

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const selectedValues = event.value as string[];
    const newVisibleColumns = columns.filter((column) =>
      selectedValues.includes(column.value)
    );

    setVisibleColumns(newVisibleColumns);
    localStorage.setItem("visibleColumns", JSON.stringify(selectedValues));
  };

  const handlePrintExport = () => {
    const query: { [key: string]: string } = {};
    query.start_date =
      dates && dates[0] ? formatDateUser(dates[0]) : formatDateUser(today);
    query.end_date =
      dates && dates[1] ? formatDateUser(dates[1]) : formatDateUser(today);
    query.s_status = statusPackage;
    if (inputSearch) query.s_global = inputSearch;
    if (Object.keys(query).length > 0) {
      dispatch(
        exportExcelPackage({
          page: (first + rows) / rows,
          limit: rows,
          query: query,
        })
      );
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex gap-3 justify-content-between">
        <Button
          label="Xuất báo cáo"
          icon="pi pi-print"
          onClick={() => handlePrintExport()}
          severity="danger"
          style={{ height: "35px" }}
        />
        <div className="flex gap-3 mb-3 sm:mt-0 md:mt-0">
          <IconField iconPosition="left" className="search-item w-6 flex gap-3">
            <InputIcon className="pi pi-search"></InputIcon>
            <InputText
              placeholder="Tìm kiếm kiện hàng"
              className=" border-1"
              style={{ height: "35px" }}
              onChange={(e) => setInputSearch(e.target.value)}
            />
          </IconField>
          <div className="calendar-container">
            <i className="pi pi-calendar text-500 mr-1" />
            <label
              className="w-max text-500"
              htmlFor="startDate"
              style={{ minWidth: "66px" }}
            >
              Ngày tạo:
            </label>
            <div className="card flex justify-content-center">
              <Calendar
                value={dates}
                onChange={(e: any) => setDates(e.value)}
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
                dateFormat="dd/mm/yy"
                locale="vi"
                placeholder="Từ ngày - Đến ngày"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-round-md shadow-1 p-3">
        <div className="flex justify-content-between align-items-center ">
          <TabMenu
            model={listStatus.map((item, index) => ({
              ...item,
              template: () =>
                tabMenuTemplate(item, index, tabMenuActive, setTabmenuActive),
            }))}
            activeIndex={tabMenuActive}
            onTabChange={(e) => setTabmenuActive(e.index)}
          />
          <div className="flex align-items-center">
            <div className="select-custom">
              <MultiSelect
                options={columns}
                value={visibleColumns.map((col) => col.value)}
                className="multiSeclect-custom"
                onChange={onColumnToggle}
                optionLabel="label"
                optionValue="value"
                dropdownIcon="pi pi-cog"
                display="chip"
                scrollHeight={"650px"}
              />
            </div>
          </div>
        </div>
        {packageReportData && packageReportData.data ? (
          <div>
            <DataTable
              value={dataTable}
              emptyMessage="Không có dữ liệu"
              size="small"
              className="package-table"
              reorderableColumns
              // rowClassName={rowClassName}
              scrollable
              onRowClick={(event) => {
                setRowData(event.data);
              }}
            >
              <Column
                header="STT"
                body={(rowData, options) => (
                  <div className="text-center">
                    {first + options.rowIndex + 1}
                  </div>
                )}
                style={{ width: "5%" }}
                alignHeader={"center"}
                align={"center"}
                frozen
              />
              {visibleColumns.map((col) => (
                <Column
                  key={col.value}
                  field={col.value}
                  header={col.label}
                  body={col.body}
                  style={{
                    minWidth: col.minWidth,
                  }}
                  frozen={
                    col.value === "status" ||
                    col.value === "carton_no" ||
                    col.value === "service_code" ||
                    col.value === "mawb" ||
                    col.value === "warehouse"
                  }
                  alignFrozen={col.value === "status" ? "right" : undefined}
                  align={col.value === "status" ? "center" : undefined}
                />
              ))}
            </DataTable>
            <div className="card flex justify-content-center align-items-center">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={packageReportData?.total}
                onPageChange={onPageChange}
              />
              <Dropdown
                value={rows}
                onChange={(e) => setRows(e.value)}
                options={rowsPerPageOptions}
                optionLabel="name"
                optionValue="code"
                style={{ height: "40px" }}
              />
            </div>
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
}
