import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  updateWarehouse,
  setWarehouseDataNull,
} from "../../store/warehouse/slice";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function ModalEditWarehouse({
  visible,
  onHide,
  toast,
  rowData,
}: {
  visible: boolean;
  onHide: () => void;
  toast: any;
  rowData: any;
}) {
  const dispatch = useDispatch();

  const { updateWarehouseData, updateWarehouseError } = useSelector(
    (state: any) => ({
      updateWarehouseData: state.Warehouse.updateWarehouseData,
      updateWarehouseError: state.Warehouse.updateWarehouseError,
    }),
    shallowEqual
  );

  // Validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Vui lòng nhập tên kho hàng"),
    code: Yup.string().required("Vui lòng nhập mã kho hàng"),
    address: Yup.string().required("Vui lòng nhập địa chỉ"),
    area: Yup.string().required("Vui lòng chọn khu vực"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: rowData?.name || "",
      code: rowData?.code || "",
      address: rowData?.address || "",
      description: rowData?.description || "",
      area: rowData?.area || undefined,
    },
    validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateWarehouse({ id: rowData?.id, data: values }));
      onHide();
    },
  });

  useEffect(() => {
    if (updateWarehouseData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Sửa kho hàng thành công",
        life: 3000,
      });

      dispatch(setWarehouseDataNull());
    }
    if (updateWarehouseError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: updateWarehouseError?.response?.data?.message,
        life: 3000,
      });

      dispatch(setWarehouseDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateWarehouseData, updateWarehouseError]);

  const header = <div>Chỉnh sửa kho hàng</div>;

  const cities = [
    { label: "Hồ Chí Minh", value: "HO_CHI_MINH" },
    { label: "Hà Nội", value: "HA_NOI" },
    { label: "Đà Nẵng", value: "DA_NANG" },
    { label: "Cần Thơ", value: "CAN_THO" },
  ];

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      header={header}
      style={{ width: "30%" }}
    >
      <div>
        <form>
          <div className="flex flex-column gap-1 flex-1 mb-3">
            <label htmlFor="name">
              Tên kho hàng <span style={{ color: "red" }}>*</span>
            </label>
            <InputText
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.name && formik.errors.name ? true : false}
            />
            {formik.touched.name && formik.errors.name ? (
              <small className="p-error">{String(formik.errors.name)}</small>
            ) : null}
          </div>
          <div className="flex flex-column gap-1 flex-1 mb-3">
            <label htmlFor="code">
              Mã kho hàng <span style={{ color: "red" }}>*</span>
            </label>
            <InputText
              id="code"
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.code && formik.errors.code ? true : false}
            />
            {formik.touched.code && formik.errors.code ? (
              <small className="p-error">{String(formik.errors.code)}</small>
            ) : null}
          </div>
          <div className="flex flex-column gap-1 flex-1 mb-3">
            <label htmlFor="address">
              Địa chỉ <span style={{ color: "red" }}>*</span>
            </label>
            <InputText
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.touched.address && formik.errors.address
                  ? "p-invalid"
                  : ""
              }
            />
            {formik.touched.address && formik.errors.address ? (
              <small className="p-error">{String(formik.errors.address)}</small>
            ) : null}
          </div>
          <div className="flex flex-column gap-1 flex-1 mb-5">
            <label htmlFor="area">
              Khu vực <span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              id="area"
              name="area"
              value={formik.values.area}
              options={cities}
              onChange={(e) => formik.setFieldValue("area", e.value)}
              onBlur={formik.handleBlur}
              placeholder="Chọn khu vực"
              optionLabel="label"
              optionValue="value"
              className={
                formik.touched.area && formik.errors.area ? "p-invalid" : ""
              }
            />
            {formik.touched.area && formik.errors.area ? (
              <small className="p-error">{String(formik.errors.area)}</small>
            ) : null}
          </div>
          <FloatLabel>
            <InputTextarea
              id="description"
              rows={4}
              className="w-full"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <label htmlFor="description">Mô tả</label>
          </FloatLabel>
        </form>
        <div className="flex gap-2 justify-content-end mt-2">
          <Button
            label="Hủy"
            severity="secondary"
            size="small"
            onClick={() => {
              onHide();
              formik.resetForm();
            }}
            style={{ width: "80px" }}
          />
          <Button
            type="submit"
            label="Lưu"
            size="small"
            onClick={() => formik.handleSubmit()}
            style={{ width: "80px" }}
          />
        </div>
      </div>
    </Dialog>
  );
}
