import React from "react";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Checkbox } from "primereact/checkbox";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import ModalAddPermission from "./ModalAddPermission";
import ModalEditPermission from "./ModalEditPermission";
import ModelDelete from "../../components/ModalDelete";
import {
  deletePermission,
  getPermissionData,
  getPermissionGroupData,
  getPermissionGroupDataById,
  setPermissionDataNull,
  updatePermission,
} from "../../store/permission/slice";
import { permissionArray } from "../../components/Interface";
import LoadingSpinner from "../../components/LoadingSpinner";
import { PermissionRequired } from "../../components/PermissionRequired";

export default function Permission() {
  const toast = useRef<any>(null);
  const dispatch = useDispatch();

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [rowData, setRowData] = useState<any>();
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const [resultArrayGroupAllData, setresultArrayGroupAllData] = useState<any>(
    []
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);

  // Hàm chuyển đổi dữ liệu sử dụng đối tượng permissionArray
  const translateToVietnamese = (text: string): string => {
    return permissionArray[text] || text;
  };

  const handleEdit = (rowData: any) => {
    setRowData(rowData);
    setDialogEditVisible(true);
  };

  //Delete group permission
  const handleDelete = (rowData: any) => {
    setModalDelete(true);
    setRowData(rowData);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData?.id;
      if (id !== undefined) {
        dispatch(deletePermission(id));
      }
      setModalDelete(false);
    }
  };

  const {
    PermissionsData,
    permissionGroupData,
    permissionGroupDataById,
    deletePermissionData,
    deletePermissionError,
    updatePermissionData,
    updatePermissionError,
    insertPermissionData,
    dataUserInfo,
  } = useSelector(
    (state: any) => ({
      PermissionsData: state.Permission.permissionData,
      permissionGroupData: state.Permission.permissionGroupData,
      permissionGroupDataById: state.Permission.permissionGroupDataById,
      deletePermissionData: state.Permission.deletePermissionData,
      deletePermissionError: state.Permission.deletePermissionError,
      updatePermissionData: state.Permission.updatePermissionData,
      updatePermissionError: state.Permission.updatePermissionError,
      insertPermissionData: state.Permission.insertPermissionData,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  // chuyển group thành 1 nhóm
  useEffect(() => {
    // Chuyển đổi dữ liệu
    if (PermissionsData) {
      const translatedData = PermissionsData?.data.map((permission: any) => ({
        id: permission.id,
        name: translateToVietnamese(permission.name),
        group: translateToVietnamese(permission.group),
      }));

      // Nhóm theo nhóm quyền
      const groupedData = translatedData.reduce((acc: any, item: any) => {
        const { group } = item;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(item);
        return acc;
      }, {});

      const resultArray = Object.keys(groupedData).map((group) => ({
        group,
        permissions: groupedData[group],
      }));
      setresultArrayGroupAllData(resultArray);
    }
  }, [PermissionsData]);

  useEffect(() => {
    dispatch(getPermissionGroupData());
    dispatch(getPermissionData());
  }, [
    dispatch,
    deletePermissionData,
    updatePermissionData,
    insertPermissionData,
  ]);

  useEffect(() => {
    if (selectedGroup) {
      dispatch(getPermissionGroupDataById({ id: selectedGroup?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  //Menu hành động
  const actionBodyTemplate = (rowData: any) => {
    if (rowData.is_default === true) {
      return null;
    }

    return (
      <div className="flex mr-3">
        {selectArrayPermisstion?.includes("UPDATE_PERMISSION_GROUP") && (
          <span
            className="pi pi-pen-to-square mr-4"
            onClick={() => handleEdit(rowData)}
          ></span>
        )}
        {selectArrayPermisstion?.includes("DELETE_PERMISSION_GROUP") && (
          <span
            className="pi pi-trash"
            onClick={() => handleDelete(rowData)}
          ></span>
        )}
      </div>
    );
  };

  const handleGroupCheck = (groupIndex: number, isChecked: boolean) => {
    const group = resultArrayGroupAllData[groupIndex];
    const updatedSelections = [...selectedCheckboxes];

    group.permissions.forEach((permission: any) => {
      const index = updatedSelections.indexOf(permission.id);
      if (isChecked && index === -1) {
        updatedSelections.push(permission.id);
      } else if (!isChecked && index !== -1) {
        updatedSelections.splice(index, 1);
      }
    });

    setSelectedCheckboxes(updatedSelections);
  };

  // Function to handle individual permission checkbox change
  const handlePermissionCheck = (permissionId: number, isChecked: boolean) => {
    const updatedSelections = [...selectedCheckboxes];
    const index = updatedSelections.indexOf(permissionId);

    if (isChecked && index === -1) {
      updatedSelections.push(permissionId);
    } else if (!isChecked && index !== -1) {
      updatedSelections.splice(index, 1);
    }

    setSelectedCheckboxes(updatedSelections);
  };

  //kiem tra gia trị ban dau vao checkbox
  useEffect(() => {
    if (permissionGroupDataById) {
      const selectedIds =
        permissionGroupDataById?.data?.permission_info?.map(
          (perm: any) => perm.id
        ) || [];
      setSelectedCheckboxes(selectedIds);
    }
  }, [permissionGroupDataById]);

  useEffect(() => {
    if (permissionGroupData?.data?.length > 0 && !selectedGroup) {
      setSelectedGroup(permissionGroupData.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionGroupData]);

  const handleSubmitPermission = () => {
    if (selectedGroup && selectedCheckboxes) {
      dispatch(
        updatePermission({
          id: selectedGroup?.id,
          name: selectedGroup?.name,
          permission_ids: selectedCheckboxes,
        })
      );
    }
  };

  useEffect(() => {
    if (deletePermissionData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Xóa nhóm quyền thành công",
        life: 3000,
      });
      dispatch(setPermissionDataNull());
      setSelectedGroup(permissionGroupData.data[0]);
    }
    if (deletePermissionError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Xóa nhóm quyền thất bại",
        life: 3000,
      });
      dispatch(setPermissionDataNull());
    }
    if (updatePermissionData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Cập nhật quyền thành công",
        life: 3000,
      });
      const { id, name } = updatePermissionData.data;
      setSelectedGroup({ id, name });
      dispatch(setPermissionDataNull());
    }
    if (updatePermissionError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${updatePermissionError?.response?.data?.message}`,
        life: 3000,
      });
      dispatch(setPermissionDataNull());
    }

    if (insertPermissionData) {
      const { id, name } = insertPermissionData.data;
      setSelectedGroup({ id, name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deletePermissionData,
    deletePermissionError,
    updatePermissionData,
    updatePermissionError,
    insertPermissionData,
  ]);

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column lg:flex-row gap-3">
        <div className="bg-white border-round-md shadow-1 p-3 flex flex-column gap-2 w-full lg:w-4 xl:w-3">
          <div className="flex justify-content-between align-items-center flex-wrap">
            <h3>Nhóm quyền</h3>
            <Button
              label="Thêm"
              disabled={
                !selectArrayPermisstion?.includes("CREATE_PERMISSION_GROUP")
              }
              onClick={() => setDialogVisible(true)}
              style={{ height: "30px", width: "100px" }}
            />
          </div>
          <div className="border-1 border-round-xl border-300">
            {permissionGroupData && permissionGroupData.data ? (
              <DataTable
                value={permissionGroupData?.data}
                onRowClick={(e: any) => setSelectedGroup(e.data)}
                selection={selectedGroup}
                selectionMode="single"
                showHeaders={false}
                emptyMessage="Chưa có nhóm quyền"
                scrollable
                scrollHeight="80vh"
                style={{ borderRadius: "10px" }}
                className="overflow-hidden"
                rowClassName={(rowData: any) => {
                  return rowData.id === selectedGroup?.id ? "active-row" : "";
                }}
              >
                <Column
                  field="name"
                  style={{ minWidth: "100px" }}
                  body={(rowData) => <div className="ml-3">{rowData.name}</div>}
                />
                <Column field="description" style={{ display: "none" }} />
                <Column
                  body={actionBodyTemplate}
                  alignHeader={"center"}
                  align={"right"}
                  style={{ width: "10%", height: "40px" }}
                />
              </DataTable>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
        <div className="bg-white border-round-md shadow-1 p-3 flex flex-column gap-2 w-full lg:w-8 xl:w-9">
          <div className="flex justify-content-between">
            <h3 className="flex align-items-center" style={{ height: "30px" }}>
              {selectedGroup?.name}
            </h3>
            {selectedGroup && (
              <Button
                label="Lưu"
                style={{ height: "30px", width: "100px" }}
                onClick={() => {
                  handleSubmitPermission();
                }}
                disabled={selectedGroup?.name === "ADMIN"}
              />
            )}
          </div>
          <div className="flex flex-column gap-1">
            <div className="flex justify-content-around flex-wrap px-5  border-round-xl border-300">
              <div className="flex flex-wrap px-5  border-round-xl border-300">
                {resultArrayGroupAllData.length > 0 ? (
                  resultArrayGroupAllData.map((key: any, index: number) => (
                    <div key={index} className="w-6 xl:w-3 my-3">
                      <div className="font-bold mb-3 text-primary flex align-items-center">
                        <Checkbox
                          checked={key.permissions.every((perm: any) =>
                            selectedCheckboxes.includes(perm.id)
                          )}
                          onChange={(e: any) =>
                            handleGroupCheck(index, e.checked)
                          }
                          inputId={`group-${index}`} // Ensure a unique ID
                          pt={{
                            root: { className: "custom-checkbox" },
                          }}
                          disabled={selectedGroup?.name === "ADMIN"}
                        />
                        <label
                          htmlFor={`group-${index}`}
                          className="ml-2 cursor-pointer"
                        >
                          {key.group}
                        </label>
                      </div>
                      {key.permissions &&
                        key.permissions.map((item: any) => (
                          <div
                            key={item.id}
                            className="font-bold mb-2 text-primary flex align-items-center"
                          >
                            <Checkbox
                              checked={selectedCheckboxes.includes(item.id)}
                              onChange={(e: any) =>
                                handlePermissionCheck(item.id, e.checked)
                              }
                              inputId={`permission-${item.id}`}
                              pt={{
                                root: { className: "custom-checkbox" },
                              }}
                              disabled={selectedGroup?.name === "ADMIN"}
                            />
                            <label
                              htmlFor={`permission-${item.id}`}
                              className="ml-2 cursor-pointer"
                            >
                              {item.name}
                            </label>
                          </div>
                        ))}
                    </div>
                  ))
                ) : (
                  <LoadingSpinner />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalAddPermission
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        toast={toast}
      />
      <ModalEditPermission
        visible={dialogEditVisible}
        onHide={() => setDialogEditVisible(false)}
        rowData={rowData}
        selectedCheckboxes={selectedCheckboxes}
      />
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg="Bạn chắc chắn muốn xóa nhóm quyền này?"
      />
    </>
  );
}
