import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { updatePlane, setPlaneDataNull } from "../../store/plane/slice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { optionsPlane } from "../../components/Interface";

export default function ModalEditPlane({
  visible,
  onHide,
  toast,
  rowData,
}: {
  visible: boolean;
  onHide: () => void;
  toast: any;
  rowData: any;
}) {
  const dispatch = useDispatch();
  const [optionWareHouse, setOptionWareHouse] = useState<any>(null);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);
  const { updatePlaneData, updatePlaneError, warehouseData, dataUserInfo } =
    useSelector(
      (state: any) => ({
        updatePlaneData: state.Plane.updatePlaneData,
        updatePlaneError: state.Plane.updatePlaneError,
        warehouseData: state.Warehouse.warehouseData,
        dataUserInfo: state.Auth.dataUserInfo,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (warehouseData?.data && warehouseData.data.length > 0) {
      const mappedWarehouses: any[] = warehouseData.data.map(
        (warehouse: any) => ({
          label: warehouse.address, // hiển thị trong Dropdown
          code: warehouse.area, // giá trị được chọn
        })
      );

      setOptionWareHouse(mappedWarehouses);
    }
  }, [warehouseData]);

  // Validation schema
  const validationSchema = Yup.object({
    code: Yup.string().required("Vui lòng nhập số hiệu chuyến bay"),
    type: Yup.string().required("Vui lòng chọn loại tàu bay"),
    departure_point: Yup.string().required("Vui lòng chọn điểm khởi hành"),
    destination: Yup.string().required("Vui lòng chọn điểm đến"),
    description: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: rowData?.code || "",
      type: rowData?.type || "",
      departure_point: rowData?.departure_point || "",
      destination: rowData?.destination || "",
      description: rowData?.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(updatePlane({ id: rowData?.id, data: values }));
      onHide();
    },
  });

  useEffect(() => {
    if (updatePlaneData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Chỉnh sửa chuyến bay thành công",
        life: 3000,
      });
      formik.resetForm();
      onHide();
      dispatch(setPlaneDataNull());
    }
    if (updatePlaneError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: updatePlaneError?.response?.data?.message,
        life: 3000,
      });
      formik.resetForm();
      onHide();
      dispatch(setPlaneDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePlaneData, updatePlaneError, dispatch]);

  useEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  return (
    <Dialog
      visible={visible}
      onHide={() => onHide()}
      header="Chỉnh sửa chuyến bay"
      className="w-10 md:w-4"
    >
      <div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="code">
            Số hiệu chuyến bay <span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="code"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.touched.code && formik.errors.code ? "p-invalid" : ""
            }
          />
          {formik.touched.code && formik.errors.code ? (
            <small className="p-error">{formik.errors.code.toString()}</small>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-1">
          <div className="flex flex-column gap-1 flex-1 mb-3">
            <label htmlFor="type">
              Loại tàu bay <span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              value={formik.values.type}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("type", e.value);
              }}
              options={optionsPlane}
              optionLabel="label"
              className="w-full"
              optionValue="code"
              placeholder="Chọn loại tàu bay"
              invalid={formik.touched.type && formik.errors.type ? true : false}
              onBlur={formik.handleBlur}
            />
            {formik.touched.type && formik.errors.type ? (
              <p
                style={{
                  color: "red",
                  marginTop: "5px",
                  fontSize: "0.9rem",
                  marginLeft: "0",
                  textAlign: "left",
                }}
              >
                {String(formik.errors.type)}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-1">
          <div className="flex flex-column gap-1 flex-1 mb-3">
            <label htmlFor="departure_point">
              Điểm khởi hành <span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              value={formik.values.departure_point}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("departure_point", e.value);
              }}
              options={optionWareHouse || []}
              optionLabel="label"
              className="w-full"
              optionValue="code"
              placeholder="Chọn địa điểm"
              emptyMessage={
                !selectArrayPermisstion?.includes("VIEW_WAREHOUSE")
                  ? "Bạn chưa được cấp quyền xem kho"
                  : ""
              }
              invalid={
                formik.touched.departure_point && formik.errors.departure_point
                  ? true
                  : false
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.departure_point && formik.errors.departure_point ? (
              <p
                style={{
                  color: "red",
                  marginTop: "5px",
                  fontSize: "0.9rem",
                  marginLeft: "0",
                  textAlign: "left",
                }}
              >
                {String(formik.errors.departure_point)}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="destination">
            Điểm đến <span style={{ color: "red" }}>*</span>
          </label>
          <Dropdown
            value={formik.values.destination}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("destination", e.value);
            }}
            options={optionWareHouse || []}
            optionLabel="label"
            className="w-full"
            optionValue="code"
            placeholder="Chọn địa điểm"
            invalid={
              formik.touched.destination && formik.errors.destination
                ? true
                : false
            }
            emptyMessage={
              !selectArrayPermisstion?.includes("VIEW_WAREHOUSE")
                ? "Bạn chưa được cấp quyền xem kho"
                : ""
            }
            onBlur={formik.handleBlur}
          />
          {formik.touched.destination && formik.errors.destination ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.destination)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="description">Mô tả</label>
          <InputTextarea
            id="description"
            name="description"
            rows={4}
            className="w-full"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Hủy"
            severity="secondary"
            size="small"
            type="button"
            onClick={() => {
              onHide();
              formik.resetForm();
            }}
            style={{ width: "80px" }}
          />
          <Button
            type="submit"
            label="Lưu"
            size="small"
            onClick={() => formik.handleSubmit()}
            style={{ width: "80px" }}
          />
        </div>
      </div>
    </Dialog>
  );
}
