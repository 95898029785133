import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import "../../assets/scss/theme-base/table.scss";
import ChartVehicle from "../Dashboard/ChartVehicle";
import ChartPackage from "../Dashboard/ChartPackage";

function Dashboard() {
  const today = new Date();
  // const [dates, setDates] = useState(null);
  const [dates, setDates] = useState<[Date, Date] | null>([today, today]);

  return (
    <div>
      <div className="calendar-container max-w-max mb-4">
        <i className="pi pi-calendar text-500 mr-1" />
        <label
          className="w-max text-500"
          htmlFor="startDate"
          style={{ minWidth: "40px" }}
        >
          Chọn ngày:
        </label>
        <div className="card flex justify-content-center">
          <Calendar
            value={dates}
            onChange={(e: any) => setDates(e.value)}
            selectionMode="range"
            readOnlyInput
            hideOnRangeSelection
            dateFormat="dd/mm/yy"
            locale="vi"
            placeholder="Từ ngày - Đến ngày"
          />
        </div>
      </div>
      <div className="flex flex-column md:flex-row gap-4">
        <div className="bg-white border-round-lg py-4 chart-factory shadow-1 w-12 md:w-6">
          <ChartVehicle
            startDate={dates ? dates[0] : null}
            endDate={dates ? dates[1] : null}
          />
        </div>
        <div className="bg-white border-round-lg py-4 chart-factory shadow-1 w-12 md:w-6">
          <ChartPackage
            startDate={dates ? dates[0] : null}
            endDate={dates ? dates[1] : null}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
