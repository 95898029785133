import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PackageState {
  packageData: any | null;
  planData: any | null;
  error: any | null;
  loading: boolean;
  loadingPackage: boolean;
  insertPackageData: any | null;
  insertPackageError: any | null;
  deletePackageData: any | null;
  deletePackageError: any | null;
  updatePackageData: any | null;
  updatePackageError: any | null;
  excelData: any | null;
  importExcelData: any | null;
  importExcelError: string | null;
  importExcelMoreData: any | null;
  importExcelMoreError: string | null;
}

const initialState: PackageState = {
  packageData: null,
  planData: null,
  error: null,
  loading: false,
  loadingPackage: false,
  insertPackageData: null,
  insertPackageError: null,
  deletePackageData: null,
  deletePackageError: null,
  updatePackageData: null,
  updatePackageError: null,
  excelData: null,
  importExcelData: null,
  importExcelError: null,
  importExcelMoreData: null,
  importExcelMoreError: null,
};

interface Package {
  warehouse_id: string;
  shopee_po_id: string;
  carton_no: string;
  name: string;
  amount_product: any;
  delivery_date: any | null;
}

const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    getPackageData: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loadingPackage = true;
    },
    getPackageDataSuccess: (state, action: PayloadAction<any>) => {
      state.loadingPackage = false;
      state.packageData = action.payload;
      state.error = null;
    },
    getPackageDataFail: (state, action: PayloadAction<any>) => {
      state.loadingPackage = false;
      state.packageData = null;
      state.error = action.payload;
    },
    getPlanData: (
      state,
      action: PayloadAction<{
        start_date: string;
        end_date: string;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getPlanDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.planData = action.payload;
      state.error = null;
    },
    getPlanDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.planData = null;
      state.error = action.payload;
    },
    deletePackage: (state) => {
      state.loading = true;
    },
    deletePackageSuccess: (state, action) => {
      state.loading = false;
      state.deletePackageData = action.payload;
      state.deletePackageError = null;
    },
    deletePackageFail: (state, action) => {
      state.loading = false;
      state.deletePackageData = null;
      state.deletePackageError = action.payload;
    },
    insertPackage: (state, action: PayloadAction<Package>) => {
      state.loading = true;
    },
    insertPackageSuccess: (state, action: PayloadAction<Package>) => {
      state.loading = false;
      state.insertPackageData = action.payload;
      state.insertPackageError = null;
    },
    insertPackageFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertPackageData = null;
      state.insertPackageError = action.payload;
    },
    updatePackage: (
      state,
      action: PayloadAction<{ id: string; data: any }>
    ) => {
      state.loading = true;
    },
    updatePackageSuccess: (state, action: PayloadAction<Package>) => {
      state.loading = false;
      state.updatePackageData = action.payload;
      state.updatePackageError = null;
    },
    updatePackageFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updatePackageData = null;
      state.updatePackageError = action.payload;
    },
    importExcel: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    importExcelSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importExcelData = action.payload;
      state.importExcelError = null;
    },
    importExcelFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importExcelData = null;
      state.importExcelError = action.payload;
    },
    importExcelMore: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    importExcelMoreSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importExcelMoreData = action.payload;
      state.importExcelMoreError = null;
    },
    importExcelMoreFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importExcelMoreData = null;
      state.importExcelMoreError = action.payload;
    },
    setPackageDataNull: (state) => {
      state.deletePackageData = null;
      state.deletePackageError = null;
      state.insertPackageData = null;
      state.insertPackageError = null;
      state.updatePackageData = null;
      state.updatePackageError = null;
      state.importExcelData = null;
      state.importExcelError = null;
      state.importExcelMoreData = null;
      state.importExcelMoreError = null;
    },
  },
});

export const {
  getPackageData,
  getPackageDataFail,
  getPackageDataSuccess,
  getPlanData,
  getPlanDataSuccess,
  getPlanDataFail,
  deletePackage,
  deletePackageFail,
  deletePackageSuccess,
  insertPackage,
  insertPackageFail,
  insertPackageSuccess,
  updatePackage,
  updatePackageFail,
  updatePackageSuccess,
  setPackageDataNull,
  importExcel,
  importExcelSuccess,
  importExcelFail,
  importExcelMore,
  importExcelMoreSuccess,
  importExcelMoreFail,
} = packageSlice.actions;

export default packageSlice.reducer;
