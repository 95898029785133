import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { SplitButton } from "primereact/splitbutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Timeline } from "primereact/timeline";
import { useNavigate } from "react-router-dom";
import "../../assets/scss/theme-base/table.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getShipmentByPhaseData,
  getListScanData,
  exportPrintList,
  setShipmentDataNull,
} from "../../store/shipment/slice";
import { phaseArray } from "../../components/Interface";
import {
  IconStartReceiving,
  IconFinishedReceiving,
  IconComeWarehouse,
  IconTransitWarehouse,
  IconSPX,
} from "../../components/Icon";
import { exportTransitPdf } from "./ExportTransitPdf";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";

function changeDateFormat(dateString?: string): string {
  if (typeof dateString !== "string") {
    return "";
  }

  const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
  const match = dateString.match(regex);

  if (!match) {
    return "";
  }

  const [, year, month, day, hours, minutes, seconds] = match;
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export default function DetailTransit() {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputSearch, setInputSearch] = useState<string>("");
  const productData = JSON.parse(localStorage.getItem("productData") || "{}");
  const toast = useRef<Toast>(null);
  const [isExportingExcel, setIsExportingExcel] = useState<boolean>(false);
  const [isExportingPDF, setIsExportingPDF] = useState<boolean>(false);
  const [isPrintPDF, setIsPrintPDF] = useState<boolean>(false);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);

  const {
    shipmentPhaseData,
    listScanData,
    exportPrintListData,
    exportPrintListError,
    dataUserInfo,
    listScanDataError,
  } = useSelector(
    (state: any) => ({
      shipmentPhaseData: state.Shipment.shipmentPhaseData,
      listScanData: state.Shipment.listScanData,
      exportPrintListData: state.Shipment.exportPrintListData,
      exportPrintListError: state.Shipment.exportPrintListError,
      listScanDataError: state.Shipment.listScanDataError,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  // Hàm chuyển đổi dữ liệu sử dụng đối tượng permissionArray
  const translateToVietnamese = (text: string): string => {
    return phaseArray[text] || text;
  };

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    if (productData) {
      dispatch(getShipmentByPhaseData({ tripId: productData?.trip_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productData.trip_id]);

  useEffect(() => {
    if (productData) {
      const timeout = setTimeout(() => {
        dispatch(
          getListScanData({
            shipment_id: productData.shipment_id,
            s_global: inputSearch,
          })
        );
      }, 400);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inputSearch, productData.shipment_id]);

  const customizedMarker = (item: any) => {
    const renderIcon = () => {
      if (translateToVietnamese(item.phase_name) === "Bắt đầu nhận hàng") {
        return <p>{IconStartReceiving}</p>;
      } else if (
        translateToVietnamese(item.phase_name) === "Nhận hàng xong" ||
        translateToVietnamese(item.phase_name) ===
          "Giao nhận hàng kho trung chuyển xong"
      ) {
        return <p>{IconFinishedReceiving}</p>;
      } else if (
        translateToVietnamese(item.phase_name) === "Xuất phát từ kho nhận" ||
        translateToVietnamese(item.phase_name) ===
          "Xuất phát từ kho trung chuyển"
      ) {
        return <p>{IconComeWarehouse}</p>;
      } else if (
        translateToVietnamese(item.phase_name) === "Đến kho trung chuyển" ||
        translateToVietnamese(item.phase_name) ===
          "Giao nhận hàng kho trung chuyển"
      ) {
        return <p>{IconTransitWarehouse}</p>;
      } else if (
        translateToVietnamese(item.phase_name) === "Đến kho SPX" ||
        translateToVietnamese(item.phase_name) === "Giao hàng kho SPX" ||
        translateToVietnamese(item.phase_name) === "Giao hàng kho SPX xong" ||
        translateToVietnamese(item.phase_name) === "Kho SPX ký nhận"
      ) {
        return <p>{IconSPX}</p>;
      }
      return null;
    };

    return (
      <span
        className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
        style={{ backgroundColor: "#295bac" }}
      >
        {renderIcon()}
      </span>
    );
  };

  const customizedContent = (item: any) => {
    return (
      <div style={{ width: "300px" }}>
        <p className="font-semibold text-base">
          {translateToVietnamese(item.phase_name)}
        </p>
        <p className="font-light text-base">
          {changeDateFormat(item.actual_time)}
        </p>
      </div>
    );
  };

  const handleNavigateClick = () => {
    navigate("/transit");
  };

  const cartonNoTemplate = (rowData: any) => {
    return (
      <>
        <p> {rowData ? rowData?.package_info?.carton_no : ""}</p>
      </>
    );
  };

  const shopeePoTemplate = (rowData: any) => {
    return (
      <>
        <p> {rowData ? rowData?.package_info.shopee_po_id : ""}</p>
      </>
    );
  };

  const nameTemplate = (rowData: any) => {
    return (
      <>
        <p> {rowData ? rowData?.package_info.name : ""}</p>
      </>
    );
  };

  //in PDF
  const handlePrint = () => {
    if (productData) {
      setIsPrintPDF(true);
      dispatch(exportPrintList({ shipmentId: productData.shipment_id }));
    }
  };

  //xuất PDF
  const handleExportPDF = () => {
    if (productData) {
      setIsExportingPDF(true);
      dispatch(exportPrintList({ shipmentId: productData.shipment_id }));
    }
  };

  const items = [
    {
      label: "Xuất Excel",
      icon: "pi pi-file-excel",
      command: () => handlePrintExcel(),
    },
    {
      label: "Xuất PDF",
      icon: "pi pi-file-pdf",
      command: () => handleExportPDF(),
    },
  ];

  const handlePrintExcel = () => {
    if (productData) {
      setIsExportingExcel(true);
      dispatch(exportPrintList({ shipmentId: productData.shipment_id }));
    }
  };

  useEffect(() => {
    if (exportPrintListData) {
      const downloadFiles = (): void => {
        const fileList = exportPrintListData?.data?.listFile;
        if (!fileList || fileList.length === 0) return;

        let index = 0;
        const intervalId = setInterval(() => {
          const fileUrl = fileList[index];
          // Tạo link và click để tải file
          const link = document.createElement("a");
          link.href = fileUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          index++;

          // Kiểm tra nếu đã tải hết file thì dừng lại
          if (index >= fileList.length) {
            clearInterval(intervalId);
          }
        }, 2000);
      };

      if (isExportingExcel) {
        toast.current?.show({
          severity: "success",
          summary: "Thành công",
          detail: `Xuất Excel thành công`,
          life: 3000,
        });
        downloadFiles();
        dispatch(setShipmentDataNull());
      } else if (isExportingPDF) {
        exportTransitPdf("save", exportPrintListData?.data?.groupBySeri);
        toast.current?.show({
          severity: "success",
          summary: "Thành công",
          detail: `Xuất PDF thành công`,
          life: 3000,
        });
      } else if (isPrintPDF) {
        exportTransitPdf("print", exportPrintListData?.data?.groupBySeri);
      }
      setIsExportingExcel(false);
      setIsExportingPDF(false);
      dispatch(setShipmentDataNull());
    }
    if (exportPrintListError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${exportPrintListError.message}`,
        life: 3000,
      });
      dispatch(setShipmentDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportPrintListData, exportPrintListError]);

  useEffect(() => {
    if (listScanDataError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${listScanDataError?.response?.data?.message}`,
        life: 3000,
      });
    }
    dispatch(setShipmentDataNull());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listScanDataError]);

  return (
    <>
      <Toast ref={toast} />
      <div
        className="bg-white border-round-md shadow-1 h-50vh"
        style={{ paddingBottom: "60px" }}
      >
        <div
          className="flex align-items-center justify-content-between"
          style={{
            backgroundColor: "##FFFFFF",
            borderBottom: "0.5px solid #DADADA",
            height: "60px",
          }}
        >
          <div className="flex align-items-center">
            <button className={`btn`} onClick={() => handleNavigateClick()}>
              <i className={`pi pi-chevron-left text-primary text-sm`}></i>
            </button>
            <div className="flex align-items-center ml-2">
              {productData?.car_info?.name ? (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 41 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20.5" cy="20.5" r="20.5" fill="#D9D9D9" />
                  <path
                    d="M29.4375 15C29.2194 14.6222 28.9061 14.308 28.529 14.0887C28.1518 13.8694 27.7238 13.7526 27.2875 13.75H24.25V11.25C24.25 10.9185 24.1183 10.6005 23.8839 10.3661C23.6495 10.1317 23.3315 10 23 10H10.5C9.83696 10 9.20107 10.2634 8.73223 10.7322C8.26339 11.2011 8 11.837 8 12.5V25C8.00045 25.4384 8.11618 25.869 8.33558 26.2485C8.55498 26.6281 8.87033 26.9433 9.25 27.1625C9.20524 28.3228 9.62326 29.4534 10.4121 30.3055C11.2009 31.1576 12.2959 31.6615 13.4562 31.7062C14.6166 31.751 15.7471 31.333 16.5993 30.5442C17.4514 29.7553 17.9552 28.6603 18 27.5H21.875C22.0132 28.5459 22.5268 29.5059 23.3202 30.2013C24.1135 30.8967 25.1325 31.2801 26.1875 31.2801C27.2425 31.2801 28.2615 30.8967 29.0548 30.2013C29.8482 29.5059 30.3618 28.5459 30.5 27.5C31.163 27.5 31.7989 27.2366 32.2678 26.7678C32.7366 26.2989 33 25.663 33 25V21.25C32.9964 21.0222 32.9363 20.7988 32.825 20.6L29.4375 15ZM24.25 16.25H27.2875L29.5375 20H24.25V16.25ZM13.625 28.75C13.2542 28.75 12.8916 28.64 12.5833 28.434C12.275 28.228 12.0346 27.9351 11.8927 27.5925C11.7508 27.2499 11.7137 26.8729 11.786 26.5092C11.8584 26.1455 12.037 25.8114 12.2992 25.5492C12.5614 25.287 12.8955 25.1084 13.2592 25.036C13.6229 24.9637 13.9999 25.0008 14.3425 25.1427C14.6851 25.2846 14.978 25.525 15.184 25.8333C15.39 26.1416 15.5 26.5042 15.5 26.875C15.5 27.3723 15.3025 27.8492 14.9508 28.2008C14.5992 28.5525 14.1223 28.75 13.625 28.75ZM26.125 28.75C25.7542 28.75 25.3916 28.64 25.0833 28.434C24.775 28.228 24.5346 27.9351 24.3927 27.5925C24.2508 27.2499 24.2137 26.8729 24.286 26.5092C24.3584 26.1455 24.537 25.8114 24.7992 25.5492C25.0614 25.287 25.3955 25.1084 25.7592 25.036C26.1229 24.9637 26.4999 25.0008 26.8425 25.1427C27.1851 25.2846 27.478 25.525 27.684 25.8333C27.89 26.1416 28 26.5042 28 26.875C28 27.3723 27.8025 27.8492 27.4508 28.2008C27.0992 28.5525 26.6223 28.75 26.125 28.75Z"
                    fill="#8F8E8E"
                  />
                </svg>
              ) : (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.04139 9.90673L6.41184 12.2459L6.81544 14.0981L4.9136 16L2.99724 13.0027L0 11.0862L1.90184 9.18434L3.8549 9.68887L6.09232 6.95756L1.37517 3.8127L3.53691 1.65012L9.43506 3.617L12.3345 0.717521C13.2296 -0.178391 14.6162 -0.244132 15.4303 0.569986C16.2444 1.3841 16.1779 2.77001 15.2828 3.66593L12.3834 6.56541L14.3487 12.4615L12.1869 14.624L9.04139 9.90673Z"
                    fill="#8F8E8E"
                  />
                </svg>
              )}

              <div className="flex flex-column ml-2 line-height-3">
                <p className="font-semibold ">
                  {productData?.car_info?.name || productData?.plane_info?.name}
                </p>
                {/* <p>
                  {changeDateFormat(productData?.start_date)} =&gt;
                  {changeDateFormat(productData?.end_date)}
                </p> */}
              </div>
            </div>
          </div>
          <div>
            <IconField
              iconPosition="left"
              className="search-item flex items-center mr-5"
              style={{ width: "400px" }}
            >
              <InputIcon className="pi pi-search"></InputIcon>
              <InputText
                placeholder="Tìm kiếm"
                className="border-1"
                style={{ height: "35px" }}
                onChange={(e) => setInputSearch(e.target.value)}
              />
            </IconField>
          </div>
        </div>
        <div
          className="flex justify-content-between align-items-center w-full"
          style={{ height: "80px" }}
        >
          <p className="font-semibold ml-3 w-4 text-gray-700" color="#555555">
            Hành trình
          </p>
          <div className="flex justify-content-between w-8 align-items-center">
            <p className="font-semibold text-gray-700">
              Danh sách kiện hàng đang vận chuyển
            </p>
            <SplitButton
              label="In bảng kê"
              icon="pi pi-print"
              onClick={handlePrint}
              model={items}
              severity="danger"
              size="small"
              className="mr-5"
              disabled={!selectArrayPermisstion?.includes("PRINT_LIST")}
            />
          </div>
        </div>
        <div className="flex w-full">
          <div
            className="timeline w-4 mr-3 justify-content-start"
            style={{ borderRight: "1px solid #BABABA" }}
          >
            <Timeline
              value={shipmentPhaseData?.data}
              className="customized-timeline"
              marker={customizedMarker}
              content={customizedContent}
              style={{ width: "100px" }}
            />
          </div>
          <div className="timeline w-8 mr-3">
            {listScanData && listScanData.data ? (
              <>
                <DataTable
                  value={listScanData?.data}
                  scrollable
                  tableStyle={{ minWidth: "50rem" }}
                  emptyMessage="Không có dữ liệu"
                >
                  <Column
                    header="STT"
                    body={(rowData, options) => (
                      <div className="text-center">
                        {first + options.rowIndex + 1}
                      </div>
                    )}
                    style={{
                      width: "5%",
                      height: "30px",
                    }}
                    alignHeader={"center"}
                    align={"center"}
                    className="custom-table"
                  />
                  <Column
                    body={cartonNoTemplate}
                    header="Mã kiện hàng"
                    style={{ minWidth: "120px" }}
                  ></Column>
                  <Column
                    body={shopeePoTemplate}
                    header="Shopee PO ID"
                    style={{ minWidth: "120px" }}
                  ></Column>
                  <Column
                    body={nameTemplate}
                    header="Tên hàng"
                    style={{ minWidth: "120px" }}
                  ></Column>
                  <Column
                    field="createdAt"
                    header="Ngày tạo"
                    style={{ minWidth: "120px" }}
                    body={(rowData) => (
                      <div>{changeDateFormat(rowData.createdAt)}</div>
                    )}
                  ></Column>
                </DataTable>
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={listScanData?.total}
                  rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                  onPageChange={onPageChange}
                />
              </>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
