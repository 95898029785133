import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import UploadFile from "../../components/UploadFile";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  importExcel,
  setPackageDataNull,
  importExcelMore,
} from "../../store/listPackage/slice";
import { DataContext } from "../../contexts/data/DataProvider";
import "../../assets/scss/theme-base/table.scss";
import { Divider } from "primereact/divider";

interface ModalImportExcelProps {
  visible: boolean;
  onHide: () => void;
  toast: any;
}

const ModalImportExcel: FC<ModalImportExcelProps> = ({
  visible,
  onHide,
  toast,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [clearFiles, setClearFiles] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    totalPackage,
    currentPackage,
    sttFile,
    setTotalPackage,
    setCurrentPackage,
    setSttFile,
  } = useContext(DataContext);
  const [excelProcessingResult, setExcelProcessingResult] = useState<any>(null);

  const header = <div>Thêm từ Excel</div>;

  const handleSubmit = () => {
    setTotalPackage("");
    setCurrentPackage("");
    if (selectedFiles.length > 0) {
      setLoading(true);
      dispatch(importExcelMore(selectedFiles));
      setExcelProcessingResult(null);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Vui lòng chọn File cần nhập vào",
        life: 3000,
      });
      return;
    }
  };

  const {
    // importExcelData,
    // importExcelError,
    importExcelMoreData,
    importExcelMoreError,
  } = useSelector(
    (state: any) => ({
      // importExcelData: state.Package.importExcelData,
      // importExcelError: state.Package.importExcelError,
      importExcelMoreData: state.Package.importExcelMoreData,
      importExcelMoreError: state.Package.importExcelMoreError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (importExcelMoreData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: importExcelMoreData?.message,
        life: 3000,
      });
      // setTotalPackage("");
      // setCurrentPackage("");
      // setTotalFileError(importExcelMoreData?.total_error);
      setExcelProcessingResult(importExcelMoreData.data);
      setSelectedFiles([]);
      setClearFiles(!clearFiles);
      dispatch(setPackageDataNull());
    }
    if (importExcelMoreError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: importExcelMoreError?.response?.data?.message,
        life: 3000,
      });
      setClearFiles(!clearFiles);
      dispatch(setPackageDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importExcelMoreData, importExcelMoreError]);

  // console.log(sttFile);
  // console.log("kiem tracurrentPackage", currentPackage);

  return (
    <>
      <Dialog
        visible={visible}
        onHide={() => {
          onHide();
          setLoading(false);
          setTotalPackage("");
          setCurrentPackage("");
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-8"
        header={header}
      >
        <>
          <UploadFile
            onUpload={() => {}}
            getFiles={setSelectedFiles}
            clearFiles={clearFiles}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
              gap: "1rem",
              alignItems: "end",
            }}
            className="w-full"
          >
            {currentPackage ? (
              <div>
                <p
                  className="font-semibold italic w-full"
                  style={{ color: "#295bac" }}
                >
                  {Number(currentPackage) > 0 &&
                    Number(currentPackage) < Number(totalPackage) && (
                      <>Đang tải lên File {sttFile}</>
                    )}
                </p>
                <div className="mb-2 w-full">
                  <progress
                    id="file"
                    value={currentPackage}
                    max={totalPackage}
                    className="custom-progress"
                    style={{ width: "215px" }}
                  ></progress>
                </div>
                <div className="w-full">
                  {excelProcessingResult && (
                    <div className="flex w-full flex-wrap">
                      {excelProcessingResult.map(
                        (result: any, index: number) => (
                          <div className="sm:w-6 xl:w-4 lg:w-6 p-2" key={index}>
                            <div className="w-full">
                              {loading && (
                                <>
                                  <Divider type="solid" />
                                  <h3 className="mb-1">
                                    Thông tin file tải lên {index + 1}
                                  </h3>
                                  <p className="text-sm mr-2 font-medium mb-2">
                                    <i
                                      className="pi pi-box"
                                      style={{
                                        color: "#708090",
                                        marginRight: "5px",
                                      }}
                                    ></i>
                                    Tổng kiện hàng:
                                    <span
                                      style={{
                                        color: "blue",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {result.total_done + result.total_error}
                                    </span>
                                  </p>
                                  <p className="text-sm mr-2 font-medium mb-1 text-teal-600">
                                    {Number(currentPackage) > 0 &&
                                      currentPackage === totalPackage && (
                                        <>
                                          <i
                                            className="pi pi-cart-plus"
                                            style={{
                                              color: "#0D9488",
                                              marginRight: "5px",
                                            }}
                                          ></i>
                                          <span className="text-teal-600 mr-2">
                                            Số kiện hàng thành công:
                                          </span>
                                          <span style={{ color: "green" }}>
                                            {result?.total_done}
                                          </span>
                                        </>
                                      )}
                                  </p>
                                  <div className="flex mt-2">
                                    <p className="text-sm mr-2 font-medium mb-1 text-red-500">
                                      {Number(currentPackage) > 0 &&
                                        currentPackage === totalPackage && (
                                          <>
                                            <i
                                              className="pi pi-cart-plus"
                                              style={{
                                                color: "red",
                                                marginRight: "5px",
                                              }}
                                            ></i>
                                            <span>Số kiện hàng lỗi: </span>
                                            {result?.total_error}
                                          </>
                                        )}
                                    </p>
                                    <p className="text-sm mr-2 font-medium mb-1 text-red-500">
                                      {Number(currentPackage) > 0 &&
                                        currentPackage === totalPackage && (
                                          <a
                                            href={result?.fileResult}
                                            download
                                            className="cursor-pointer text-red-500 font-bold"
                                          >
                                            (Tải File lỗi)
                                          </a>
                                        )}
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
              gap: "1rem",
              alignItems: "end",
            }}
          >
            <div></div>
            <div className="w-100 d-flex justify-content-end">
              <Button
                label="Huỷ"
                className="px-5 py-2 mr-2 "
                type="submit"
                style={{
                  backgroundColor: "#808080",
                  color: "white",
                  width: "100px", // Set a fixed width for consistgit ency
                }}
                onClick={() => {
                  onHide();
                  setLoading(false);
                  setTotalPackage("");
                  setCurrentPackage("");
                }}
              />
              <Button
                label="Nhập"
                className="px-5 py-2 mt-2"
                type="submit"
                style={{
                  width: "100px", // Set the same fixed width
                }}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};

export default ModalImportExcel;
