import React from "react";
import { Dialog } from "primereact/dialog";
import { Timeline } from "primereact/timeline";
import { phaseArray } from "../../components/Interface";
import {
  IconComeWarehouse,
  IconFinishedReceiving,
  IconSPX,
  IconStartReceiving,
  IconTransitWarehouse,
} from "../../components/Icon";
import { changeDateFormat } from "../../components/utils";

interface TimelineEvent {
  status?: string;
  date?: string;
  license_plate: string;
  icon?: any;
  color?: string;
  image?: string;
}

export default function ModalDetailPackage({
  visible,
  onHide,
  rowData,
}: {
  visible: boolean;
  onHide: () => void;
  rowData: any;
}) {
  const events: TimelineEvent[] = [
    {
      status: "Bắt đầu nhận hàng",
      date: "17/08/2024 - 16:30",
      license_plate: "BKS9012",
      icon: (
        <svg
          width="13"
          height="22"
          viewBox="0 0 19 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5 21L18 16V6L9.5 1L1 6V16L9.5 21ZM9.5 21V11.625M9.5 11.625L1.52535 6.625M9.5 11.625L17.4746 6.625"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      color: "#3749A6",
    },
    {
      status: "Nhận hàng xong",
      date: "15/10/2020 14:00",
      license_plate: "BKS9012",
      icon: (
        <svg
          width="40"
          height="30"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="22" cy="22" r="22" fill="#3749A6" />
          <path
            d="M29.6133 16.8475L21.3066 12L13 16.8475V26.5424L21.3066 31.3898M29.6133 16.8475L21.3066 22.3008M29.6133 16.8475V21.6949M21.3066 31.3898V22.3008M21.3066 31.3898L23.1045 30.3407M21.3066 22.3008L13.5134 17.4534M24.9035 19.2712L17.1103 14.4237M26.1025 27.7542L27.3014 28.9661L30.8983 25.3305"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      color: "#3749A6",
    },
    {
      status: "Xuẩt phát từ kho nhận",
      date: "15/10/2020 16:15",
      license_plate: "BKS9012",
      icon: (
        <svg
          width="18"
          height="16"
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="flex"
        >
          <path
            d="M0.185547 0.169922V1.52585H13.0266V11.6953H8.86613C8.5647 10.5299 7.51984 9.66145 6.26817 9.66145C5.0165 9.66145 3.97164 10.5299 3.67022 11.6953H2.88894V8.30552H1.53724V13.0513H3.67022C3.97164 14.2167 5.0165 15.0852 6.26817 15.0852C7.51984 15.0852 8.5647 14.2167 8.86613 13.0513H14.4838C14.7852 14.2167 15.8301 15.0852 17.0817 15.0852C18.3334 15.0852 19.3783 14.2167 19.6797 13.0513H21.8127V7.52179L21.7701 7.41535L20.4184 3.34755L20.2717 2.88179H14.3783V0.169922H0.185547ZM0.861394 2.88179V4.23772H6.94402V2.88179H0.861394ZM14.3783 4.23772H19.2992L20.461 7.7123V11.6953H19.6797C19.3783 10.5299 18.3334 9.66145 17.0817 9.66145C15.8301 9.66145 14.7852 10.5299 14.4838 11.6953H14.3783V4.23772ZM1.53724 5.59365V6.94958H5.59233V5.59365H1.53724ZM6.26817 11.0174C7.0231 11.0174 7.61987 11.616 7.61987 12.3733C7.61987 13.1306 7.0231 13.7292 6.26817 13.7292C5.51325 13.7292 4.91648 13.1306 4.91648 12.3733C4.91648 11.616 5.51325 11.0174 6.26817 11.0174ZM17.0817 11.0174C17.8367 11.0174 18.4334 11.616 18.4334 12.3733C18.4334 13.1306 17.8367 13.7292 17.0817 13.7292C16.3268 13.7292 15.73 13.1306 15.73 12.3733C15.73 11.616 16.3268 11.0174 17.0817 11.0174Z"
            fill="white"
          />
        </svg>
      ),
      color: "#3749A6",
    },
    {
      status: "Đến kho trung chuyển",
      date: "16/10/2020 10:00",
      license_plate: "BKS9012",
      icon: (
        <svg
          width="45"
          height="30"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="22" cy="22" r="22" fill="#3749A6" />
          <path
            d="M23 14.1699V15.5259H24.0265V25.6953H19.866C19.5646 24.5299 18.5197 23.6614 17.268 23.6614C16.0164 23.6614 14.9715 24.5299 14.6701 25.6953H13.8888V22.3055H12.5371V27.0513H14.6701C14.9715 28.2167 16.0164 29.0852 17.268 29.0852C18.5197 29.0852 19.5646 28.2167 19.866 27.0513H25.4836C25.7851 28.2167 26.8299 29.0852 28.0816 29.0852C29.3333 29.0852 30.3781 28.2167 30.6796 27.0513H32.8125V21.5218L32.77 21.4153L31.4183 17.3475L31.2716 16.8818H25.3782V14.1699H23ZM25.3782 18.2377H30.2991L31.4608 21.7123V25.6953H30.6796C30.3781 24.5299 29.3333 23.6614 28.0816 23.6614C26.8299 23.6614 25.7851 24.5299 25.4836 25.6953H25.3782V18.2377ZM17.268 25.0174C18.023 25.0174 18.6197 25.616 18.6197 26.3733C18.6197 27.1306 18.023 27.7292 17.268 27.7292C16.5131 27.7292 15.9163 27.1306 15.9163 26.3733C15.9163 25.616 16.5131 25.0174 17.268 25.0174ZM28.0816 25.0174C28.8365 25.0174 29.4333 25.616 29.4333 26.3733C29.4333 27.1306 28.8365 27.7292 28.0816 27.7292C27.3267 27.7292 26.7299 27.1306 26.7299 26.3733C26.7299 25.616 27.3267 25.0174 28.0816 25.0174Z"
            fill="white"
          />
          <path
            d="M18.7456 15.8794L16.375 18.25L15.5669 17.4419M17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      color: "#3749A6",
    },
  ];

  //   const customizedMarker = (item: TimelineEvent) => {
  //     return (
  //       <span
  //         className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
  //         style={{ backgroundColor: item.color }}
  //       >
  //         <p>{item.icon}</p>
  //       </span>
  //     );
  //   };

  const customizedContent = (item: TimelineEvent) => {
    return (
      <div style={{ minWidth: "300px" }}>
        <p className="font-semibold">{item.status}</p>
        <p className="font-light text-sm font-normal">
          BKS/ Số chuyến bay: {item.license_plate}
        </p>
        <p className="font-light text-sm font-normal">{item.date}</p>
      </div>
    );
  };
  const header = (
    <div className="flex align-items-center">
      <div className="flex align-items-center ml-2">
        <i
          className="pi pi-box surface-300 p-2 border-circle text-500"
          style={{ fontSize: "1.5rem" }}
        />
        <div className="flex flex-column ml-2 text-base gap-2">
          <p>{rowData?.carton_no}</p>
          <p className="text-sm font-normal">
            {/* Hôm nay - 16:30 =&gt; Hôm nay - 17:30 */}
            Ngày nhận {changeDateFormat(rowData?.delivery_date)}
          </p>
        </div>
      </div>
    </div>
  );

  const translateToVietnamese = (text: string): string => {
    return phaseArray[text] || text;
  };

  const customizedMarker = (item: any) => {
    const renderIcon = () => {
      if (translateToVietnamese(item.phase_name) === "Bắt đầu nhận hàng") {
        return <p>{IconStartReceiving}</p>;
      } else if (
        translateToVietnamese(item.phase_name) === "Nhận hàng xong" ||
        translateToVietnamese(item.phase_name) ===
          "Giao nhận hàng kho trung chuyển xong"
      ) {
        return <p>{IconFinishedReceiving}</p>;
      } else if (
        translateToVietnamese(item.phase_name) === "Xuất phát từ kho nhận" ||
        translateToVietnamese(item.phase_name) ===
          "Xuất phát từ kho trung chuyển"
      ) {
        return <p>{IconComeWarehouse}</p>;
      } else if (
        translateToVietnamese(item.phase_name) === "Đến kho trung chuyển" ||
        translateToVietnamese(item.phase_name) ===
          "Giao nhận hàng kho trung chuyển"
      ) {
        return <p>{IconTransitWarehouse}</p>;
      } else if (
        translateToVietnamese(item.phase_name) === "Đến kho SPX" ||
        translateToVietnamese(item.phase_name) === "Giao hàng kho SPX" ||
        translateToVietnamese(item.phase_name) === "Giao hàng kho SPX xong" ||
        translateToVietnamese(item.phase_name) === "Kho SPX ký nhận"
      ) {
        return <p>{IconSPX}</p>;
      }
      return null;
    };

    return (
      <span
        className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
        style={{ backgroundColor: "#295bac" }}
      >
        {renderIcon()}
      </span>
    );
  };

  return (
    <div>
      <Dialog
        visible={visible}
        onHide={() => onHide()}
        header={header}
        className="w-10 md:w-4"
        style={{ height: "80vh" }}
      >
        <div className="flex justify-content-between align-items-center w-full pb-5">
          <p className="font-semibold ml-3 w-4 text-gray-700" color="#555555">
            Hành trình
          </p>
        </div>
        <div
          className="timeline w-4 mr-3 justify-content-start"
          // style={{ borderRight: "1px solid #BABABA" }}
        >
          <Timeline
            value={rowData?.shipment}
            className="customized-timeline"
            marker={customizedMarker}
            content={customizedContent}
            style={{ width: "100px" }}
          />
        </div>
      </Dialog>
    </div>
  );
}
