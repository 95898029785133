import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  importExcelSuccess,
  importExcelFail,
  getContainerDataSuccess,
  getContainerDataFail,
  getContainerInfoDataSuccess,
  getContainerInfoDataFail,
  exportExcelSuccess,
  exportExcelFail,
} from "./slice";
import { IMPORT_EXCEL_CONTAINER } from "../../api/url_helper";
import {
  getAllContainer,
  getInfoContainer,
  exportExcelContainer,
} from "../../api/backend_helper";
import axios from "axios";

function* fetchAllContainerDataSaga(
  action: PayloadAction<{ page: number; limit: number; query: {} }>
): Generator<any, void, any> {
  try {
    const { page, limit, query } = action.payload;
    const response = yield call(getAllContainer, page, limit, query);
    yield put(getContainerDataSuccess(response));
  } catch (error: any) {
    yield put(getContainerDataFail(error));
  }
}

function* fetchContainerInfoDataSaga(
  action: PayloadAction<{
    containerId: number;
    isShipped: boolean;
    page: number;
    limit: number;
    query: {};
  }>
): Generator<any, void, any> {
  try {
    const { page, limit, query, containerId, isShipped } = action.payload;
    const response = yield call(
      getInfoContainer,
      containerId,
      isShipped,
      page,
      limit,
      query
    );
    yield put(getContainerInfoDataSuccess(response));
  } catch (error: any) {
    yield put(getContainerInfoDataFail(error));
  }
}

function* importExcelSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const { files, date_select } = action.payload;

    const formData = new FormData();
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
    }
    const response = yield call(
      axios.post,
      `${IMPORT_EXCEL_CONTAINER}?date_select=${date_select}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    yield put(importExcelSuccess(response.data));
  } catch (error) {
    yield put(importExcelFail(error));
  }
}

function* exportExcelContainerDataSaga(
  action: PayloadAction<{
    containerId: number;
    isShipped: boolean;
    page: number;
    limit: number;
    query: {};
  }>
): Generator<any, void, any> {
  try {
    const { page, limit, query, containerId, isShipped } = action.payload;
    const response = yield call(
      exportExcelContainer,
      containerId,
      isShipped,
      page,
      limit,
      query
    );
    yield put(exportExcelSuccess(response));
  } catch (error: any) {
    yield put(exportExcelFail(error));
  }
}

function* ContainerSaga(): Generator<any, void, any> {
  yield takeLatest("container/getContainerData", fetchAllContainerDataSaga);
  yield takeLatest(
    "container/getContainerInfoData",
    fetchContainerInfoDataSaga
  );
  yield takeLatest("container/importExcel", importExcelSaga);
  yield takeLatest("container/exportExcel", exportExcelContainerDataSaga);
}

export default ContainerSaga;
