import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import "../../assets/scss/theme-base/table.scss";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import LoadingSpinner from "../../components/LoadingSpinner";
import ModalDetailContainer from "./ModalDetailContainer";
import { IconContainerCustom } from "../../components/Icon";
import ModalImportExcelContainer from "./ModalImportExcelContainer";
import { getContainerData } from "../../store/container/slice";
import { changeDateFormat, formatDateUser } from "../../components/utils";

export default function Container() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const today = new Date();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [modalDetail, setModalDetail] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [modelOpenImportExcel, setmodelOpenImportExcel] =
    useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [dates, setDates] = useState<[Date, Date] | null>([today, today]);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const { containerData, dataUserInfo } = useSelector(
    (state: any) => ({
      containerData: state.Container.containerData,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const query: { [key: string]: string } = {};

      if (inputSearch) query.s_global = inputSearch;
      query.start_date =
        dates && dates[0] ? formatDateUser(dates[0]) : formatDateUser(today);
      query.end_date =
        dates && dates[1] ? formatDateUser(dates[1]) : formatDateUser(today);

      if (Object.keys(query).length > 0) {
        dispatch(
          getContainerData({
            page: (first + rows) / rows,
            limit: rows,
            query: query,
          })
        );
      } else {
        dispatch(
          getContainerData({ page: (first + rows) / rows, limit: rows })
        );
      }
    }, 400);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rows, first, inputSearch, dates]);

  const rowClassName = (rowData: any, rowIndex: any) => {
    return "custom-row";
  };

  const statusContainerNameTemplate = (rowData: any) => {
    return (
      <div className="flex flex-row align-items-center py-2 gap-2">
        {IconContainerCustom}
        <div>{rowData?.name}</div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex gap-3 justify-content-between">
        <div>
          <Button
            label="Nhập từ Excel"
            className="mr-3"
            onClick={() => {
              setmodelOpenImportExcel(true);
            }}
            style={{ height: "35px" }}
            disabled={!selectArrayPermisstion?.includes("IMPORT_EXCEL")}
          />
        </div>
        <div className="flex gap-3 mb-3 sm:mt-0 md:mt-0">
          <IconField iconPosition="left" className="search-item w-6 flex gap-3">
            <InputIcon className="pi pi-search"></InputIcon>
            <InputText
              placeholder="Tìm kiếm Container"
              className=" border-1"
              style={{ height: "35px" }}
              onChange={(e) => setInputSearch(e.target.value)}
            />
          </IconField>

          <div className="calendar-container">
            <i className="pi pi-calendar text-500 mr-1" />
            <label
              className="w-max text-500"
              htmlFor="startDate"
              style={{ minWidth: "66px" }}
            >
              Ngày tạo:
            </label>
            <div className="card flex justify-content-center">
              <Calendar
                value={dates}
                onChange={(e: any) => setDates(e.value)}
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
                dateFormat="dd/mm/yy"
                locale="vi"
                placeholder="Từ ngày - Đến ngày"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-round-md shadow-1 p-3">
        {containerData && containerData.data ? (
          <div>
            <DataTable
              value={containerData?.data}
              emptyMessage="Không có dữ liệu"
              size="small"
              className="package-table"
              reorderableColumns
              rowClassName={rowClassName}
              scrollable
              onRowClick={(event) => {
                setModalDetail(true);
                setRowData(event.data);
              }}
            >
              <Column
                header="STT"
                body={(rowData, options) => (
                  <div className="text-center">
                    {first + options.rowIndex + 1}
                  </div>
                )}
                style={{ width: "5%" }}
                alignHeader={"center"}
                align={"center"}
                frozen
              />
              <Column
                field="name"
                header="Tên Container"
                body={statusContainerNameTemplate}
              ></Column>
              <Column field="total_package" header="Số lượng"></Column>
              <Column
                header="Ngày nhận"
                body={(rowData) => (
                  <div>{changeDateFormat(rowData?.delivery_date)}</div>
                )}
              ></Column>
              <Column
                field="total_package_shipped"
                header="Số kiện hàng đã chuyển"
                align={"center"}
              ></Column>
              <Column
                field="total_package_not_shipped"
                header="Số kiện hàng chưa chuyển"
                align={"center"}
              ></Column>
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={containerData?.total}
              rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
              onPageChange={onPageChange}
            />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <ModalImportExcelContainer
        visible={modelOpenImportExcel}
        onHide={() => setmodelOpenImportExcel(false)}
        toast={toast}
      />
      <ModalDetailContainer
        visible={modalDetail}
        onHide={() => setModalDetail(false)}
        rowData={rowData}
      />
    </div>
  );
}
