// export const site = "http://localhost:3104";
export const api_address = "https://address.itomo.vn";

export const site = "https://api.hxlogistics.net";
// export const site = "https://api.logistics.itomo.one";

//user
export const LOGIN = `${site}/api/v1/auth/login`;
export const LOGOUT = `${site}/api/v1/auth/logout`;
export const GET_ALL_USER = `${site}/api/v1/auth/get-all-user`;
export const REGISTER = `${site}/api/v1/auth/register`;
export const UPDATE_USER = `${site}/api/v1/auth/update-user`;
export const DELETE_USER = `${site}/api/v1/auth/delete-user`;
export const RESET_PASSWORD = `${site}/api/v1/auth/reset-password`;
export const UPLOAD_FILE = `${site}/api/v1/auth/update-avatar`;
export const UPDATE_PROFILE = `${site}/api/v1/auth/update-profile`;
export const GET_INFO = `${site}/api/v1/auth/get-info`;
export const CHANGE_PASSWORD = `${site}/api/v1/auth/change-password`;

//warehouse
export const GET_ALL_WAREHOUSE = `${site}/api/v1/warehouse`;

//car
export const GET_ALL_CARS = `${site}/api/v1/cars`;
export const INSERT_DATA_CAR = `${site}/api/v1/cars`;
export const UPDATE_DATA_CAR = `${site}/api/v1/cars`;
export const DELETE_DATA_CAR = `${site}/api/v1/cars`;

//plane
export const GET_ALL_PLANE = `${site}/api/v1/planes`;

// phase
export const GET_ALL_PHASE = `${site}/api/v1/phase`;
export const INSERT_DATA_PHASE = `${site}/api/v1/phase`;
export const UPDATE_DATA_PHASE = `${site}/api/v1/phase`;
export const DELETE_DATA_PHASE = `${site}/api/v1/phase`;

//package
export const GET_ALL_PACKAGE = `${site}/api/v1/package`;
export const GET_ALL_PLAN = `${site}/api/v1/package/plan/show`;
export const IMPORT_DATA_EXCEL = `${site}/api/v1/package/import-excel`;
export const IMPORT_MORE_DATA_EXCEL = `${site}/api/v1/package/import-more-excel`;

// Permission
export const GET_ALL_PERMISSION = `${site}/api/v1/permissions/get-all-permission`;
export const GET_PERMISSION_BY_ID = `${site}/api/v1/permissions`;
export const GET_ALL_PERMISSION_GROUP = `${site}/api/v1/permissions/get-all-permission-group`;
export const INSERT_PERMISSION = `${site}/api/v1/permissions`;
export const UPDATE_PERMISSION = `${site}/api/v1/permissions`;
export const DELETE_PERMISSION = `${site}/api/v1/permissions`;

//transit
export const GET_ALl_TRANSIT = `${site}/api/v1/shipment/get-shipment-on-transit`;
export const EXPORT_PRINT_LIST = `${site}/api/v1/shipment/print-list`;

//shipment
export const GET_SHIPMENT_ON_PHASE = `${site}/api/v1/shipment/get-shipment-by-phase?trip_id=`;
export const GET_LIST_SCAN = `${site}/api/v1/shipment-detail/get-list-scan`;

// container
export const IMPORT_EXCEL_CONTAINER = `${site}/api/v1/containers`;
export const GET_ALL_CONTAINER = `${site}/api/v1/containers`;
export const GET_INFO_CONTAINER_BY_ID = `${site}/api/v1/containers/get-detail`;
export const EXPORT_EXCEL_CONTAINER = `${site}/api/v1/containers/export`;

// report
export const GET_PACKAGE_REPORT = `${site}/api/v1/report`;
export const EXPORT_PACKAGE_REPORT = `${site}/api/v1/report/export-excel`;

// customer
export const CUSTOMER_API = `${site}/api/v1/customer`;

// dashboard
export const GET_DATA_DASHBOARD_BY_PACKAGE = `${site}/api/v1/dashboard/data-by-package`;
export const GET_DATA_DASHBOARD_BY_CAR = `${site}/api/v1/dashboard/data-by-car`;

//notification
export const NOTIFICATION_API = `${site}/api/v1/notification`;
export const NOTIFICATION_ONLY_SEEN = `${site}/api/v1/notification/seen`;
export const NOTIFICATION_ALL_SEEN = `${site}/api/v1/notification/seen-all`;
