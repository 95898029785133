/* eslint-disable react/jsx-no-comment-textnodes */
import React, { FC, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import UploadFile from "../../components/UploadFile";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import "../../assets/scss/theme-base/table.scss";
import { importExcel, setContainerDataNull } from "../../store/container/slice";
import { Calendar } from "primereact/calendar";
import { formatDateUser } from "../../components/utils";
import { ProgressSpinner } from "primereact/progressspinner";

interface ModalImportExcelProps {
  visible: boolean;
  onHide: () => void;
  toast: any;
}

const ModalImportExcelContainer: FC<ModalImportExcelProps> = ({
  visible,
  onHide,
  toast,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [clearFiles, setClearFiles] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const today = new Date();
  // const [dates, setDates] = useState<Date | null>(null);
  const [dates, setDates] = useState<Date | null>(today);
  const header = <div>Thêm từ Excel</div>;

  const handleSubmit = () => {
    if (selectedFiles.length > 0) {
      setLoading(true);
      dispatch(
        importExcel({
          files: selectedFiles,
          date_select: dates ? formatDateUser(dates) : formatDateUser(today),
        })
      );
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Vui lòng chọn File cần nhập vào",
        life: 3000,
      });
      return;
    }
  };

  const { importExcelContainerData, importExcelContainerError } = useSelector(
    (state: any) => ({
      importExcelContainerData: state.Container.importExcelContainerData,
      importExcelContainerError: state.Container.importExcelContainerError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (importExcelContainerData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: importExcelContainerData?.message,
        life: 3000,
      });
      setSelectedFiles([]);
      setClearFiles(!clearFiles);
      setLoading(false);
      setDates(today);
      dispatch(setContainerDataNull());
    }
    if (importExcelContainerError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: importExcelContainerError?.response?.data?.message,
        life: 3000,
      });
      setLoading(false);
      setClearFiles(!clearFiles);
      setDates(today);
      dispatch(setContainerDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importExcelContainerData, importExcelContainerError]);

  return (
    <>
      <Dialog
        visible={visible}
        onHide={() => {
          onHide();
          setLoading(false);
          setClearFiles(!clearFiles);
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-8"
        header={header}
      >
        <>
          <div className="calendar-container my-2 w-3">
            <i className="pi pi-calendar text-500 mr-1" />
            <label
              className="w-max text-500"
              htmlFor="startDate"
              style={{ minWidth: "66px" }}
            >
              Ngày tạo:
            </label>
            <div className="card flex justify-content-center">
              <Calendar
                value={dates}
                onChange={(e: any) => setDates(e.value)}
                readOnlyInput
                hideOnRangeSelection
                dateFormat="dd/mm/yy"
                locale="vi"
                placeholder="Chọn ngày"
              />
            </div>
          </div>
          <UploadFile
            onUpload={() => {}}
            getFiles={setSelectedFiles}
            clearFiles={clearFiles}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "60px",
              gap: "1rem",
              alignItems: "end",
            }}
            className="w-full"
          >
            {loading ? (
              <div className="">
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="4"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              </div>
            ) : (
              <div></div>
            )}
            <div className="w-100 d-flex justify-content-end">
              <Button
                label="Huỷ"
                className="px-5 py-2 mr-2 "
                type="submit"
                style={{
                  backgroundColor: "#808080",
                  color: "white",
                  width: "100px",
                }}
                onClick={() => {
                  onHide();
                  setLoading(false);
                }}
              />
              <Button
                label="Nhập"
                className="px-5 py-2 mt-2"
                type="submit"
                style={{
                  width: "100px",
                }}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};

export default ModalImportExcelContainer;
