import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ContainerState {
  containerData: any | null;
  containerInfoData: any | null;
  error: any | null;
  loading: boolean;
  loadingInfo: boolean;
  containerInfoDataFail: any | null;
  excelContainerData: any | null;
  importExcelContainerData: any | null;
  importExcelContainerError: any | null;
  exportExcelContainerData: any | null;
  exportExcelContainerError: any | null;
}

const initialState: ContainerState = {
  containerData: null,
  containerInfoData: null,
  error: null,
  loading: false,
  loadingInfo: false,
  excelContainerData: null,
  containerInfoDataFail: null,
  importExcelContainerData: null,
  importExcelContainerError: null,
  exportExcelContainerData: null,
  exportExcelContainerError: null,
};

const containerSlice = createSlice({
  name: "container",
  initialState,
  reducers: {
    getContainerData: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getContainerDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.containerData = action.payload;
      state.error = null;
    },
    getContainerDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.containerData = null;
      state.error = action.payload;
    },
    getContainerInfoData: (
      state,
      action: PayloadAction<{
        containerId: number;
        isShipped: boolean;
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loadingInfo = true;
    },
    getContainerInfoDataSuccess: (state, action: PayloadAction<any>) => {
      state.loadingInfo = false;
      state.containerInfoData = action.payload;
      state.error = null;
    },
    getContainerInfoDataFail: (state, action: PayloadAction<any>) => {
      state.loadingInfo = false;
      state.containerInfoData = null;
      state.containerInfoDataFail = action.payload;
    },
    importExcel: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    importExcelSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importExcelContainerData = action.payload;
      state.importExcelContainerError = null;
    },
    importExcelFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importExcelContainerData = null;
      state.importExcelContainerError = action.payload;
    },
    exportExcel: (
      state,
      action: PayloadAction<{
        containerId: number;
        isShipped: boolean;
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    exportExcelSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.exportExcelContainerData = action.payload;
      state.exportExcelContainerError = null;
    },
    exportExcelFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.exportExcelContainerData = null;
      state.exportExcelContainerError = action.payload;
    },
    setContainerDataNull: (state) => {
      state.error = null;
      state.importExcelContainerData = null;
      state.importExcelContainerError = null;
      state.exportExcelContainerData = null;
      state.exportExcelContainerError = null;
    },
  },
});

export const {
  getContainerData,
  getContainerDataSuccess,
  getContainerDataFail,
  getContainerInfoData,
  getContainerInfoDataSuccess,
  getContainerInfoDataFail,
  importExcel,
  importExcelSuccess,
  importExcelFail,
  exportExcel,
  exportExcelSuccess,
  exportExcelFail,
  setContainerDataNull,
} = containerSlice.actions;

export default containerSlice.reducer;
